$color-button-border-focus: $color-brand-200;

@mixin button-colors(
  $background-color,
  $font-color,
  $hover-color,
  $active-color
) {
  color: $font-color;
  background-color: $background-color;
  border-color: $background-color;

  &:focus {
    color: $font-color;
    background-color: $background-color;
  }

  &:hover {
    color: $font-color;
    background-color: $hover-color;
    border-color: $hover-color;
  }

  &:active {
    color: $font-color;
    background-color: $active-color;
    border-color: $active-color;
  }

  &.v3__button--variant-VariantOutlined {
    color: $background-color;
    background-color: transparent;
  }
}

@mixin text-button-colors($color, $hover-color) {
  padding-right: 0;
  padding-left: 0;
  color: $color;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }

  &:disabled {
    .v3__button--label-wrapper {
      border-color: $color-grayscale-300;
    }
  }

  &:focus,
  &:hover {
    color: $hover-color;
    background-color: transparent;
    border-color: $hover-color;

    .v3__button--label-wrapper {
      border-color: $hover-color;
    }
  }

  &:active {
    color: $color-button-primary-pressed;

    .v3__button--label-wrapper {
      border-color: $color-button-primary-pressed;
    }
  }
}

@mixin text-button-padding($vertical-padding) {
  padding: $vertical-padding 0 0;

  .v3__button--label-wrapper {
    padding: var(--space-xxxs);
  }
}
@mixin button-icon-dimensions($svg-dimension) {
  .v3__icon {
    width: $svg-dimension;
    height: $svg-dimension;
  }
}

@mixin filled-disabled-styles {
  color: $color-grayscale-500;
  background-color: $color-grayscale-300;
  border-color: $color-grayscale-300;
}

@mixin outlined-disabled-styles {
  color: $color-grayscale-500;
  background-color: transparent;
  border-color: $color-grayscale-300;
}

// This can be used to reset all button styles in cases where you need to
// use a <button> element for other purposes
@mixin v3-button-reset {
  display: block;
  margin: 0;
  font-weight: inherit; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
  font-size: inherit; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
  background: none;
  border: none;
  transition: none;

  &:hover,
  &:focus {
    color: inherit; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
    background: inherit;
  }
}

.v3__button {
  --border-width: 2px;
  --large-button-vertical-padding: var(--space-xxs);
  --medium-button-vertical-padding: calc(
    var(--space-xxs) - var(--border-width)
  );
  --small-button-vertical-padding: calc(
    var(--space-xxxs) - var(--border-width)
  );
  --large-button-horizontal-padding: var(--space-xs);
  --medium-button-horizontal-padding: var(--space-xs);
  --small-button-horizontal-padding: var(--space-xxs);

  display: inline-flex;
  align-items: center;
  height: auto;
  margin: 0;
  font-weight: $button-font-weight;
  font-family: $button-font-family;
  white-space: nowrap;
  border-style: solid;
  border-width: var(--border-width);
  border-radius: var(--space-xxs);
  outline: none;
  cursor: pointer;
  transition: none;
}

.v3__button:not(:disabled) {
  &:focus:not(:hover, :active) {
    outline: solid var(--border-width) $color-button-border-focus;
  }
}

.v3__button__plain {
  @include v3-button-reset;

  cursor: pointer;
}

.v3__button--color-ColorPrimary {
  @include button-colors(
    $color-button-primary-default,
    $color-brand-0,
    $color-button-primary-hover,
    $color-button-primary-pressed
  );

  &.v3__button--variant-VariantText {
    @include text-button-colors(
      $color-button-primary-default,
      $color-button-primary-hover
    );
  }
}

.v3__button--color-ColorSecondary {
  @include button-colors(
    $color-button-secondary-background,
    $color-button-secondary-border-default,
    $color-button-secondary-border-hover,
    $color-button-secondary-border-pressed
  );

  border-color: $color-button-secondary-border-default;

  &:hover {
    color: $color-button-secondary-background;
  }

  &.v3__button--variant-VariantText {
    @include text-button-colors(
      $color-button-secondary-text-default,
      $color-button-secondary-text-hover
    );
  }
}

.v3__button--color-ColorDestruct {
  @include button-colors(
    $color-danger-600,
    $color-brand-0,
    $color-danger-700,
    $color-danger-800
  );

  &.v3__button--variant-VariantText {
    @include text-button-colors($color-danger-600, $color-danger-700);
  }
}

// DEPRECATED: QuickPay (green/success) color buttons no longer exist in the updated V3 Design System
.v3__button--color-ColorQuickPay {
  @include button-colors(
    $color-success-400,
    #00253e,
    $color-success-100,
    $color-success-100
  );

  &.v3__button--variant-VariantText {
    @include text-button-colors($color-success-500, $color-success-400);
  }
}

.v3__button--utility {
  color: $color-utility-button-icon-default;
  background-color: transparent;
  border-color: $color-utility-button-border-default;
  border-radius: 4px;
  outline: none !important;

  &:hover,
  &:focus {
    background: $color-grayscale-200;
    border-color: $color-brand-600;
  }

  &:active {
    background: $color-grayscale-400;
    border-color: $color-brand-600;
  }
}

.v3__button--utility--highlighted {
  background-color: $color-utility-button-fill-active;
  border-color: $color-utility-button-border-active;
}

.v3__button--utility__primary-no-border {
  color: $color-button-primary-default;
  border: 0;

  &:hover,
  &:active,
  &:focus {
    color: $color-button-primary-hover;
    background: none;
    border: 0;
  }

  &:focus-visible {
    outline: solid 2px $color-brand-200 !important;
  }
}

// Disabled buttons are all styled the same regardless of their original color;
// therefore, we make this the last rule, overriding the specifications above.
.v3__button[disabled],
button.v3__button[disabled] /* Override for global styles outside of v3 CSS */ {
  cursor: auto;

  &.v3__button--variant-VariantFilled:disabled {
    @include filled-disabled-styles;

    &:hover {
      @include filled-disabled-styles;
    }
  }

  &.v3__button--variant-VariantOutlined:disabled,
  &.v3__button--variant-VariantText:disabled {
    @include outlined-disabled-styles;

    &:hover {
      @include outlined-disabled-styles;
    }
  }

  &.v3__button--disabled-while-loading {
    cursor: wait;
  }
}

.v3__button--label-wrapper {
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
}

.v3__button--utility-label {
  font-weight: var(--font-weight-regular);
}

.v3__button--size-SizeLarge {
  padding: var(--large-button-vertical-padding)
    var(--large-button-horizontal-padding);
  font-size: var(--font-size-body);
  line-height: calc(var(--font-size-body) + 4px);

  @include button-icon-dimensions(calc(var(--font-size-body) + 4px));

  &.v3__button--square {
    padding: var(--large-button-vertical-padding);
  }

  &.v3__button--variant-VariantText {
    @include text-button-padding(var(--large-button-vertical-padding));
  }
}

.v3__button--size-SizeMedium,
.v3__button--utility--size-small {
  padding: var(--medium-button-vertical-padding)
    var(--medium-button-horizontal-padding);
  font-size: var(--font-size-h5);
  line-height: calc(var(--font-size-h5) + 4px);

  @include button-icon-dimensions(calc(var(--font-size-h5) + 4px));

  &.v3__button--square {
    padding: var(--medium-button-vertical-padding);
  }

  &.v3__button--variant-VariantText {
    @include text-button-padding(var(--medium-button-vertical-padding));
  }
}

.v3__button--size-SizeSmall {
  padding: var(--small-button-vertical-padding)
    var(--small-button-horizontal-padding);
  font-size: var(--font-size-h6);
  line-height: calc(var(--font-size-h6) + 4px);

  @include button-icon-dimensions(calc(var(--font-size-h6) + 4px));

  &.v3__button--square {
    padding: var(--small-button-vertical-padding);
  }

  &.v3__button--variant-VariantText {
    @include text-button-padding(var(--small-button-vertical-padding));
  }
}
