.v3__notification {
  display: flex;
  gap: var(--space-xxs);
  padding: var(--space-xs);
  font-size: var(--font-size-extra-small-body);
  line-height: 1.5em;
  border-radius: var(--corner-radius-m);

  .v3__notification-icon {
    margin: 0;
    line-height: 0;
  }

  .v3__notification-text-title {
    display: inline;
    font-weight: var(--font-weight-bold);

    &::after {
      content: " ";
    }
  }
}

.v3__notification-text {
  flex: 1;
}

.v3__notification-close,
button.v3__notification-close /* Override for global styles outside of v3 CSS */ {
  margin: 0;
  padding: 0;

  // TODO: should use --color-grayscale-900 here after the "X" icon size is updated to match the new designs
  color: $color-grayscale-500;
  background: none;
  border: none;
  cursor: pointer;
}

.v3__notification > .v3__notification-text {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xxxs);
  align-items: baseline;
}

.v3__notification > .v3__notification-text--column {
  flex-direction: column;
}

.v3__notification--inline {
  align-items: flex-start;
}

// toast-specific

.v3__notification--toast {
  align-items: center;
  box-sizing: border-box;
  width: 356px;
  max-width: 100%;
  box-shadow: 0 0 8px 0 rgb(61 62 64 / 0.16);
}

.v3__notification-icon--toast svg {
  width: 20px;
  height: 20px;
}

button.v3__notification-close--toast {
  padding: 8px;
}

// Colors

@mixin v3-notification-color($background, $border, $icon-color) {
  &.v3__notification {
    background: $background;
    border: 1px solid $border;
  }

  & .v3__notification-icon {
    color: $icon-color;
  }
}

.v3__notification--success {
  @include v3-notification-color(
    $color-success-100,
    $color-success-400,
    $color-success-600
  );
}

.v3__notification--error {
  @include v3-notification-color(
    $color-danger-100,
    $color-danger-300,
    $color-danger-500
  );
}

.v3__notification--warning {
  @include v3-notification-color(
    $color-warning-100,
    $color-warning-300,
    $color-warning-500
  );
}

.v3__notification--info {
  @include v3-notification-color(
    $color-blue-100,
    $color-blue-200,
    $color-blue-400
  );
}
