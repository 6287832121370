// taken from https://www.figma.com/file/p0qhjnEEtJdnVcox4zAPLN/3.0-Design-System?type=design&node-id=8969-113075&mode=dev
.v3__theme {
  --font-size-h1: 36px;
  --font-height-h1: 44px;
  --font-weight-h1: 700;

  --font-size-h1-mobile: 28px;
  --font-height-h1-mobile: 33px;
  --font-weight-h1-mobile: 700;

  --font-size-h2: 24px;
  --font-height-h2: 30px;
  --font-weight-h2: 700;

  --font-size-h2-mobile: 22px;
  --font-height-h2-mobile: 27px;
  --font-weight-h2-mobile: 700;

  --font-size-h3: 20px;
  --font-height-h3: 24px;
  --font-weight-h3: 700;

  --font-size-h3-mobile: 18px;
  --font-height-h3-mobile: 23px;
  --font-weight-h3-mobile: 700;

  --font-size-h4: 16px;
  --font-height-h4: 20px;
  --font-weight-h4: 700;

  --font-size-h5: 14px;
  --font-height-h5: 18px;
  --font-weight-h5: 700;

  --font-size-h6: 12px;
  --font-height-h6: 15px;
  --font-weight-h6: 700;

  --font-size-body: 16px;
  --font-height-body: 24px;
  --font-weight-body: 400;

  --font-size-body-small: 14px;
  --font-height-body-small: 21px;
  --font-weight-body-small: 400;

  --font-size-body-x-small: 12px;
  --font-height-body-x-small: 18px;
  --font-weight-body-x-small: 400;

  --font-size-body-x-x-small: 10px;
  --font-height-body-x-x-small: 16px;
  --font-weight-body-x-x-small: 400;

  --font-size-semi-bold: var(--font-size-body);
  --font-height-semi-bold: var(--font-height-body);
  --font-weight-semi-bold: 600;

  --font-size-semi-bold-small: var(--font-size-body-small);
  --font-height-semi-bold-small: var(--font-height-body-small);
  --font-weight-semi-bold-small: 600;

  --font-size-semi-bold-x-small: var(--font-size-body-x-small);
  --font-height-semi-bold-x-small: var(--font-height-body-x-small);
  --font-weight-semi-bold-x-small: 600;

  --font-size-label: var(--font-size-body-x-small);
  --font-height-label: var(--font-height-body-x-small);
  --font-weight-label: var(--font-weight-semi-bold);

  --font-size-h-small: 10px;
  --font-height-h-small: 12px;
  --font-weight-h-small: 700;

  --font-size-link: var(--font-size-body);
  --font-height-link: var(--font-height-body);
  --font-weight-link: 700;

  --font-size-link-small: var(--font-size-body-small);
  --font-height-link-small: var(--font-height-body-small);
  --font-weight-link-small: 700;

  --font-size-link-x-small: var(--font-size-body-x-small);
  --font-height-link-x-small: var(--font-height-body-x-small);
  --font-weight-link-x-small: 700;

  --font-size-link-x-x-small: var(--font-size-body-x-x-small);
  --font-height-link-x-x-small: var(--font-height-body-x-x-small);
  --font-weight-link-x-x-small: 700;

  --font-size-link--embedded: var(--font-size-body);
  --font-height-link--embedded: var(--font-height-body);
  --font-weight-link--embedded: 400;

  --font-size-link-small--embedded: var(--font-size-body-small);
  --font-height-link-small--embedded: var(--font-height-body-small);
  --font-weight-link-small--embedded: 400;

  --font-size-link-x-small--embedded: var(--font-size-body-x-small);
  --font-height-link-x-small--embedded: var(--font-height-body-x-small);
  --font-weight-link-x-small--embedded: 400;

  --font-size-link-x-x-small--embedded: var(--font-size-body-x-x-small);
  --font-height-link-x-x-small--embedded: var(--font-height-body-x-x-small);
  --font-weight-link-x-x-small--embedded: 400;
}

$font-size-h1: var(--font-size-h1);
$font-height-h1: var(--font-height-h1);
$font-weight-h1: var(--font-weight-h1);

$font-size-h2: var(--font-size-h2);
$font-height-h2: var(--font-height-h2);
$font-weight-h2: var(--font-weight-h2);

$font-size-h3: var(--font-size-h3);
$font-height-h3: var(--font-height-h3);
$font-weight-h3: var(--font-weight-h3);

$font-size-h4: var(--font-size-h4);
$font-height-h4: var(--font-height-h4);
$font-weight-h4: var(--font-weight-h4);

$font-size-h5: var(--font-size-h5);
$font-height-h5: var(--font-height-h5);
$font-weight-h5: var(--font-weight-h5);

$font-size-h6: var(--font-size-h6);
$font-height-h6: var(--font-height-h6);
$font-weight-h6: var(--font-weight-h6);

$font-size-label: var(--font-size-label);
$font-height-label: var(--font-height-label);
$font-weight-label: var(--font-weight-label);

$font-size-h-small: var(--font-size-h-small);
$font-height-h-small: var(--font-height-h-small);
$font-weight-h-small: var(--font-weight-h-small);

$font-size-body: var(--font-size-body);
$font-height-body: var(--font-height-body);
$font-weight-body: var(--font-weight-body);

$font-size-body-small: var(--font-size-body-small);
$font-height-body-small: var(--font-height-body-small);
$font-weight-body-small: var(--font-weight-body-small);

$font-size-body-x-small: var(--font-size-body-x-small);
$font-height-body-x-small: var(--font-height-body-x-small);
$font-weight-body-x-small: var(--font-weight-body-x-small);

$font-size-body-x-x-small: var(--font-size-body-x-x-small);
$font-height-body-x-x-small: var(--font-height-body-x-x-small);
$font-weight-body-x-x-small: var(--font-weight-body-x-x-small);

$font-size-semi-bold: var(--font-size-semi-bold);
$font-height-semi-bold: var(--font-height-semi-bold);
$font-weight-semi-bold: var(--font-weight-semi-bold);

$font-size-semi-bold-small: var(--font-size-semi-bold-small);
$font-height-semi-bold-small: var(--font-height-semi-bold-small);
$font-weight-semi-bold-small: var(--font-weight-semi-bold-small);

$font-size-semi-bold-x-small: var(--font-size-semi-bold-x-small);
$font-height-semi-bold-x-small: var(--font-height-semi-bold-x-small);
$font-weight-semi-bold-x-small: var(--font-weight-semi-bold-x-small);

$font-size-link: var(--font-size-link);
$font-height-link: var(--font-height-link);
$font-weight-link: var(--font-weight-link);

$font-size-link-small: var(--font-size-link-small);
$font-height-link-small: var(--font-height-link-small);
$font-weight-link-small: var(--font-weight-link-small);

$font-size-link-x-small: var(--font-size-link-x-small);
$font-height-link-x-small: var(--font-height-link-x-small);
$font-weight-link-x-small: var(--font-weight-link-x-small);

$font-size-link-x-x-small: var(--font-size-link-x-x-small);
$font-height-link-x-x-small: var(--font-height-link-x-x-small);
$font-weight-link-x-x-small: var(--font-weight-link-x-x-small);

$font-size-link--embedded: var(--font-size-link--embedded);
$font-height-link--embedded: var(--font-height-link--embedded);
$font-weight-link--embedded: var(--font-weight-link--embedded);

$font-size-link-small--embedded: var(--font-size-link-small--embedded);
$font-height-link-small--embedded: var(--font-height-link-small--embedded);
$font-weight-link-small--embedded: var(--font-weight-link-small--embedded);

$font-size-link-x-small--embedded: var(--font-size-link-x-small--embedded);
$font-height-link-x-small--embedded: var(--font-height-link-x-small--embedded);
$font-weight-link-x-small--embedded: var(--font-weight-link-x-small--embedded);

$font-size-link-x-x-small--embedded: var(--font-size-link-x-x-small--embedded);
$font-height-link-x-x-small--embedded: var(
  --font-height-link-x-x-small--embedded
);
$font-weight-link-x-x-small--embedded: var(
  --font-weight-link-x-x-small--embedded
);

$fonts-list:
  h1, h2, h3, h4, h5, h6, label, h-small, body, body-small, body-x-small,
  body-x-x-small, link, link-small, link-x-small, link-x-x-small,
  link--embedded, link-small--embedded, link-x-small--embedded,
  link-x-x-small--embedded, semi-bold, semi-bold-small, semi-bold-x-small;

@each $key in $fonts-list {
  .v3__font--#{$key} {
    font-family: inter;
    font-size: var(--font-size-#{$key});
    line-height: var(--font-height-#{$key});
    font-weight: var(--font-weight-#{$key});
  }
}

$mobile-font-overrides-list: h1, h2, h3;
@each $key in $mobile-font-overrides-list {
  .v3__mobile-device .v3__font--#{$key} {
    font-family: inter;
    font-size: var(--font-size-#{$key}-mobile);
    line-height: var(--font-height-#{$key}-mobile);
    font-weight: var(--font-weight-#{$key}-mobile);
  }
}

@each $key in $fonts-list {
  @if (
    $key ==
      "link" or
      $key ==
      "link-small" or
      $key ==
      "link-x-small" or
      $key ==
      "link-x-x-small" or
      $key ==
      "link--embedded" or
      $key ==
      "link-small--embedded" or
      $key ==
      "link-x-small--embedded" or
      $key ==
      "link-x-x-small--embedded"
  ) {
    .v3__font--#{$key} {
      color: var(--color-green-500);
      cursor: pointer;
    }

    .v3__font--#{$key}:hover {
      color: var(--color-green-700);
    }
  }
}

@each $key in $fonts-list {
  @if (
    $key ==
      "link--embedded" or
      $key ==
      "link-small--embedded" or
      $key ==
      "link-x-small--embedded" or
      $key ==
      "link-x-x-small--embedded"
  ) {
    .v3__font--#{$key} {
      text-decoration: underline;
    }
  }
}
