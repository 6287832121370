/*
 * DO NOT EDIT MANUALLY. Code generated by bin/generate_colors.rb.
 * Based on https://www.figma.com/file/p0qhjnEEtJdnVcox4zAPLN/3.0-Design-System?type=design&node-id=14470-659&mode=design&t=961i0viGcnwr0DIU-0
 */

/*
 * Prefer using scss variables over css variables
 * wherever possible, because it gives compile-time
 * gurantees.
 */

/* stylelint-disable scss/dollar-variable-pattern, color-hex-length */
$color-blue-100: var(--color-blue-100);
$color-blue-150: var(--color-blue-150);
$color-blue-200: var(--color-blue-200);
$color-blue-300: var(--color-blue-300);
$color-blue-400: var(--color-blue-400);
$color-blue-500: var(--color-blue-500);
$color-blue-600: var(--color-blue-600);
$color-blue-700: var(--color-blue-700);
$color-blue-800: var(--color-blue-800);
$color-blue-900: var(--color-blue-900);
$color-brown-100: var(--color-brown-100);
$color-brown-200: var(--color-brown-200);
$color-brown-300: var(--color-brown-300);
$color-brown-400: var(--color-brown-400);
$color-brown-450: var(--color-brown-450);
$color-brown-500: var(--color-brown-500);
$color-brown-550: var(--color-brown-550);
$color-brown-600: var(--color-brown-600);
$color-brown-700: var(--color-brown-700);
$color-brown-800: var(--color-brown-800);
$color-brown-900: var(--color-brown-900);
$color-danger-100: var(--color-danger-100);
$color-danger-200: var(--color-danger-200);
$color-danger-300: var(--color-danger-300);
$color-danger-400: var(--color-danger-400);
$color-danger-500: var(--color-danger-500);
$color-danger-600: var(--color-danger-600);
$color-danger-700: var(--color-danger-700);
$color-danger-800: var(--color-danger-800);
$color-green-100: var(--color-green-100);
$color-green-150: var(--color-green-150);
$color-green-200: var(--color-green-200);
$color-green-300: var(--color-green-300);
$color-green-400: var(--color-green-400);
$color-green-500: var(--color-green-500);
$color-green-600: var(--color-green-600);
$color-green-700: var(--color-green-700);
$color-green-800: var(--color-green-800);
$color-green-900: var(--color-green-900);
$color-navy-0: var(--color-navy-0);
$color-navy-100: var(--color-navy-100);
$color-navy-200: var(--color-navy-200);
$color-navy-300: var(--color-navy-300);
$color-navy-400: var(--color-navy-400);
$color-navy-450: var(--color-navy-450);
$color-navy-500: var(--color-navy-500);
$color-navy-550: var(--color-navy-550);
$color-navy-600: var(--color-navy-600);
$color-navy-700: var(--color-navy-700);
$color-navy-800: var(--color-navy-800);
$color-navy-900: var(--color-navy-900);
$color-overlays-scrim: var(--color-overlays-scrim);
$color-overlays-thumbnail: var(--color-overlays-thumbnail);
$color-pink-100: var(--color-pink-100);
$color-pink-200: var(--color-pink-200);
$color-pink-300: var(--color-pink-300);
$color-pink-400: var(--color-pink-400);
$color-pink-500: var(--color-pink-500);
$color-pink-600: var(--color-pink-600);
$color-pink-700: var(--color-pink-700);
$color-success-100: var(--color-success-100);
$color-success-200: var(--color-success-200);
$color-success-300: var(--color-success-300);
$color-success-400: var(--color-success-400);
$color-success-500: var(--color-success-500);
$color-success-600: var(--color-success-600);
$color-success-700: var(--color-success-700);
$color-teal-100: var(--color-teal-100);
$color-teal-200: var(--color-teal-200);
$color-teal-300: var(--color-teal-300);
$color-teal-400: var(--color-teal-400);
$color-teal-500: var(--color-teal-500);
$color-teal-600: var(--color-teal-600);
$color-teal-700: var(--color-teal-700);
$color-violet-100: var(--color-violet-100);
$color-violet-200: var(--color-violet-200);
$color-violet-300: var(--color-violet-300);
$color-violet-400: var(--color-violet-400);
$color-violet-500: var(--color-violet-500);
$color-violet-600: var(--color-violet-600);
$color-violet-700: var(--color-violet-700);
$color-warning-100: var(--color-warning-100);
$color-warning-200: var(--color-warning-200);
$color-warning-300: var(--color-warning-300);
$color-warning-400: var(--color-warning-400);
$color-warning-500: var(--color-warning-500);
$color-warning-600: var(--color-warning-600);
$color-warning-700: var(--color-warning-700);

// semantic colors
$color-background-container: var(--color-background-container);
$color-background-document-thumbnail: var(
  --color-background-document-thumbnail
);
$color-background-document-viewer: var(--color-background-document-viewer);
$color-background-navigation: var(--color-background-navigation);
$color-background-product: var(--color-background-product);
$color-border-default: var(--color-border-default);
$color-border-disabled: var(--color-border-disabled);
$color-border-focus: var(--color-border-focus);
$color-border-hover: var(--color-border-hover);
$color-border-selected: var(--color-border-selected);
$color-brand-0: var(--color-brand-0);
$color-brand-100: var(--color-brand-100);
$color-brand-150: var(--color-brand-150);
$color-brand-200: var(--color-brand-200);
$color-brand-300: var(--color-brand-300);
$color-brand-400: var(--color-brand-400);
$color-brand-500: var(--color-brand-500);
$color-brand-600: var(--color-brand-600);
$color-brand-700: var(--color-brand-700);
$color-brand-800: var(--color-brand-800);
$color-brand-900: var(--color-brand-900);
$color-button-primary-default: var(--color-button-primary-default);
$color-button-primary-disabled: var(--color-button-primary-disabled);
$color-button-primary-hover: var(--color-button-primary-hover);
$color-button-primary-pressed: var(--color-button-primary-pressed);
$color-button-primary-text-diabled: var(--color-button-primary-text-diabled);
$color-button-secondary-background: var(--color-button-secondary-background);
$color-button-secondary-border-default: var(
  --color-button-secondary-border-default
);
$color-button-secondary-border-disabled: var(
  --color-button-secondary-border-disabled
);
$color-button-secondary-border-hover: var(
  --color-button-secondary-border-hover
);
$color-button-secondary-border-pressed: var(
  --color-button-secondary-border-pressed
);
$color-button-secondary-text-default: var(
  --color-button-secondary-text-default
);
$color-button-secondary-text-disabled: var(
  --color-button-secondary-text-disabled
);
$color-button-secondary-text-hover: var(--color-button-secondary-text-hover);
$color-button-secondary-text-pressed: var(
  --color-button-secondary-text-pressed
);
$color-button-tertiary-button-text-disabled: var(
  --color-button-tertiary-button-text-disabled
);
$color-button-tertiary-text-default: var(--color-button-tertiary-text-default);
$color-button-tertiary-text-hover: var(--color-button-tertiary-text-hover);
$color-button-tertiary-text-pressed: var(--color-button-tertiary-text-pressed);
$color-danger-button-primary-background-disabled: var(
  --color-danger-button-primary-background-disabled
);
$color-danger-button-primary-button-default: var(
  --color-danger-button-primary-button-default
);
$color-danger-button-primary-hover: var(--color-danger-button-primary-hover);
$color-danger-button-primary-pressed: var(
  --color-danger-button-primary-pressed
);
$color-danger-button-primary-text-disabled: var(
  --color-danger-button-primary-text-disabled
);
$color-danger-button-secondary-border-default: var(
  --color-danger-button-secondary-border-default
);
$color-danger-button-secondary-border-disabled: var(
  --color-danger-button-secondary-border-disabled
);
$color-danger-button-secondary-border-hover: var(
  --color-danger-button-secondary-border-hover
);
$color-danger-button-secondary-border-pressed: var(
  --color-danger-button-secondary-border-pressed
);
$color-danger-button-secondary-text-default: var(
  --color-danger-button-secondary-text-default
);
$color-danger-button-secondary-text-disabled: var(
  --color-danger-button-secondary-text-disabled
);
$color-danger-button-secondary-text-hover: var(
  --color-danger-button-secondary-text-hover
);
$color-danger-button-secondary-text-pressed: var(
  --color-danger-button-secondary-text-pressed
);
$color-danger-button-tertiary-text-default: var(
  --color-danger-button-tertiary-text-default
);
$color-danger-button-tertiary-text-disabled: var(
  --color-danger-button-tertiary-text-disabled
);
$color-danger-button-tertiary-text-hover: var(
  --color-danger-button-tertiary-text-hover
);
$color-danger-button-tertiary-text-pressed: var(
  --color-danger-button-tertiary-text-pressed
);
$color-grayscale-0: var(--color-grayscale-0);
$color-grayscale-100: var(--color-grayscale-100);
$color-grayscale-200: var(--color-grayscale-200);
$color-grayscale-300: var(--color-grayscale-300);
$color-grayscale-400: var(--color-grayscale-400);
$color-grayscale-450: var(--color-grayscale-450);
$color-grayscale-500: var(--color-grayscale-500);
$color-grayscale-550: var(--color-grayscale-550);
$color-grayscale-600: var(--color-grayscale-600);
$color-grayscale-700: var(--color-grayscale-700);
$color-grayscale-800: var(--color-grayscale-800);
$color-grayscale-900: var(--color-grayscale-900);
$color-utility-button-border-active: var(--color-utility-button-border-active);
$color-utility-button-border-default: var(
  --color-utility-button-border-default
);
$color-utility-button-border-hover: var(--color-utility-button-border-hover);
$color-utility-button-fill-active: var(--color-utility-button-fill-active);
$color-utility-button-fill-hover: var(--color-utility-button-fill-hover);
$color-utility-button-icon-default: var(--color-utility-button-icon-default);
$color-utility-button-icon-active: var(--color-utility-button-icon-active);
$color-utility-button-icon-hover: var(--color-utility-button-icon-hover);

.v3__theme {
  --color-blue-100: hsl(210deg 100% 98%);
  --color-blue-150: hsl(207deg 100% 90%);
  --color-blue-200: hsl(207deg 100% 78%);
  --color-blue-300: hsl(207deg 100% 70%);
  --color-blue-400: hsl(207deg 100% 58%);
  --color-blue-500: hsl(207deg 100% 51%);
  --color-blue-600: hsl(207deg 100% 40%);
  --color-blue-700: hsl(207deg 100% 30%);
  --color-blue-800: hsl(209deg 100% 22%);
  --color-blue-900: hsl(206deg 100% 14%);
  --color-brown-100: hsl(30deg 9% 98%);
  --color-brown-200: hsl(30deg 14% 96%);
  --color-brown-300: hsl(30deg 14% 89%);
  --color-brown-400: hsl(30deg 10% 80%);
  --color-brown-450: hsl(30deg 8% 72%);
  --color-brown-500: hsl(30deg 6% 55%);
  --color-brown-550: hsl(28deg 6% 47%);
  --color-brown-600: hsl(30deg 7% 39%);
  --color-brown-700: hsl(32deg 8% 32%);
  --color-brown-800: hsl(30deg 8% 24%);
  --color-brown-900: hsl(36deg 8% 12%);
  --color-danger-100: hsl(9deg 100% 98%);
  --color-danger-200: hsl(9deg 100% 92%);
  --color-danger-300: hsl(9deg 100% 84%);
  --color-danger-400: hsl(9deg 100% 76%);
  --color-danger-500: hsl(9deg 100% 64%);
  --color-danger-600: hsl(9deg 100% 41%);
  --color-danger-700: hsl(9deg 100% 32%);
  --color-danger-800: hsl(9deg 100% 24%);
  --color-green-100: hsl(148deg 38% 92%);
  --color-green-150: hsl(148deg 39% 84%);
  --color-green-200: hsl(155deg 38% 68%);
  --color-green-300: hsl(158deg 38% 52%);
  --color-green-400: hsl(164deg 55% 41%);
  --color-green-500: hsl(164deg 64% 30%);
  --color-green-600: hsl(164deg 73% 25%);
  --color-green-700: hsl(164deg 74% 21%);
  --color-green-800: hsl(151deg 59% 14%);
  --color-green-900: hsl(157deg 87% 9%);
  --color-navy-0: hsl(0deg 0% 100%);
  --color-navy-100: hsl(218deg 42% 98%);
  --color-navy-200: hsl(220deg 33% 96%);
  --color-navy-300: hsl(213deg 27% 92%);
  --color-navy-400: hsl(211deg 28% 85%);
  --color-navy-450: hsl(208deg 20% 73%);
  --color-navy-500: hsl(209deg 19% 63%);
  --color-navy-550: hsl(209deg 19% 53%);
  --color-navy-600: hsl(209deg 20% 43%);
  --color-navy-700: hsl(209deg 30% 28%);
  --color-navy-800: hsl(209deg 40% 17%);
  --color-navy-900: hsl(204deg 80% 8%);
  --color-overlays-scrim: hsl(203deg 70% 10% / 0.3);
  --color-overlays-thumbnail: hsl(207deg 100% 40% / 0.16);
  --color-pink-100: hsl(300deg 79% 97%);
  --color-pink-200: hsl(300deg 78% 94%);
  --color-pink-300: hsl(303deg 100% 83%);
  --color-pink-400: hsl(302deg 87% 73%);
  --color-pink-500: hsl(297deg 72% 59%);
  --color-pink-600: hsl(297deg 64% 44%);
  --color-pink-700: hsl(297deg 64% 34%);
  --color-success-100: hsl(145deg 67% 94%);
  --color-success-200: hsl(146deg 78% 86%);
  --color-success-300: hsl(145deg 70% 74%);
  --color-success-400: hsl(145deg 62% 69%);
  --color-success-500: hsl(145deg 77% 44%);
  --color-success-600: hsl(145deg 77% 34%);
  --color-success-700: hsl(145deg 100% 24%);
  --color-teal-100: hsl(170deg 84% 94%);
  --color-teal-200: hsl(170deg 83% 86%);
  --color-teal-300: hsl(170deg 61% 61%);
  --color-teal-400: hsl(170deg 60% 47%);
  --color-teal-500: hsl(170deg 69% 38%);
  --color-teal-600: hsl(170deg 85% 27%);
  --color-teal-700: hsl(170deg 85% 20%);
  --color-violet-100: hsl(254deg 100% 96%);
  --color-violet-200: hsl(254deg 100% 93%);
  --color-violet-300: hsl(254deg 100% 86%);
  --color-violet-400: hsl(254deg 100% 79%);
  --color-violet-500: hsl(254deg 99% 71%);
  --color-violet-600: hsl(254deg 72% 58%);
  --color-violet-700: hsl(254deg 72% 48%);
  --color-warning-100: hsl(45deg 100% 94%);
  --color-warning-200: hsl(44deg 100% 78%);
  --color-warning-300: hsl(44deg 100% 72%);
  --color-warning-400: hsl(44deg 100% 65%);
  --color-warning-500: hsl(43deg 100% 58%);
  --color-warning-600: hsl(41deg 96% 48%);
  --color-warning-700: hsl(25deg 100% 36%);

  // semantic colors
  --color-background-container: var(--color-navy-0);
  --color-background-document-thumbnail: var(--color-navy-0);
  --color-background-document-viewer: var(--color-navy-200);
  --color-background-navigation: var(--color-navy-0);
  --color-background-product: var(--color-navy-100);
  --color-border-default: var(--color-navy-400);
  --color-border-disabled: var(--color-navy-300);
  --color-border-focus: var(--color-navy-900);
  --color-border-hover: var(--color-navy-500);
  --color-border-selected: var(--color-blue-600);
  --color-brand-0: var(--color-navy-0);
  --color-brand-100: var(--color-blue-100);
  --color-brand-150: var(--color-blue-150);
  --color-brand-200: var(--color-blue-200);
  --color-brand-300: var(--color-blue-300);
  --color-brand-400: var(--color-blue-400);
  --color-brand-500: var(--color-blue-500);
  --color-brand-600: var(--color-blue-600);
  --color-brand-700: var(--color-blue-700);
  --color-brand-800: var(--color-blue-800);
  --color-brand-900: var(--color-blue-900);
  --color-button-primary-default: var(--color-blue-600);
  --color-button-primary-disabled: var(--color-navy-300);
  --color-button-primary-hover: var(--color-blue-700);
  --color-button-primary-pressed: var(--color-blue-800);
  --color-button-primary-text-diabled: var(--color-navy-500);
  --color-button-secondary-background: var(--color-navy-0);
  --color-button-secondary-border-default: var(--color-blue-600);
  --color-button-secondary-border-disabled: var(--color-navy-300);
  --color-button-secondary-border-hover: var(--color-blue-700);
  --color-button-secondary-border-pressed: var(--color-blue-800);
  --color-button-secondary-text-default: var(--color-blue-600);
  --color-button-secondary-text-disabled: var(--color-navy-500);
  --color-button-secondary-text-hover: var(--color-blue-700);
  --color-button-secondary-text-pressed: var(--color-blue-800);
  --color-button-tertiary-button-text-disabled: var(--color-navy-500);
  --color-button-tertiary-text-default: var(--color-blue-600);
  --color-button-tertiary-text-hover: var(--color-blue-700);
  --color-button-tertiary-text-pressed: var(--color-blue-800);
  --color-danger-button-primary-background-disabled: var(--color-navy-300);
  --color-danger-button-primary-button-default: var(--color-danger-600);
  --color-danger-button-primary-hover: var(--color-danger-700);
  --color-danger-button-primary-pressed: var(--color-danger-800);
  --color-danger-button-primary-text-disabled: var(--color-navy-500);
  --color-danger-button-secondary-border-default: var(--color-danger-600);
  --color-danger-button-secondary-border-disabled: var(--color-navy-300);
  --color-danger-button-secondary-border-hover: var(--color-danger-700);
  --color-danger-button-secondary-border-pressed: var(--color-danger-800);
  --color-danger-button-secondary-text-default: var(--color-danger-600);
  --color-danger-button-secondary-text-disabled: var(--color-navy-500);
  --color-danger-button-secondary-text-hover: var(--color-danger-700);
  --color-danger-button-secondary-text-pressed: var(--color-danger-800);
  --color-danger-button-tertiary-text-default: var(--color-danger-600);
  --color-danger-button-tertiary-text-disabled: var(--color-navy-500);
  --color-danger-button-tertiary-text-hover: var(--color-danger-700);
  --color-danger-button-tertiary-text-pressed: var(--color-danger-800);
  --color-grayscale-0: var(--color-navy-0);
  --color-grayscale-100: var(--color-navy-100);
  --color-grayscale-200: var(--color-navy-200);
  --color-grayscale-300: var(--color-navy-300);
  --color-grayscale-400: var(--color-navy-400);
  --color-grayscale-450: var(--color-navy-450);
  --color-grayscale-500: var(--color-navy-500);
  --color-grayscale-550: var(--color-navy-550);
  --color-grayscale-600: var(--color-navy-600);
  --color-grayscale-700: var(--color-navy-700);
  --color-grayscale-800: var(--color-navy-800);
  --color-grayscale-900: var(--color-navy-900);
  --color-utility-button-border-active: var(--color-blue-600);
  --color-utility-button-border-default: var(--color-navy-400);
  --color-utility-button-border-hover: var(--color-blue-600);
  --color-utility-button-fill-active: var(--color-navy-400);
  --color-utility-button-fill-hover: var(--color-navy-200);
  --color-utility-button-icon-default: var(--color-navy-600);
  --color-utility-button-icon-active: var(--color-navy-600);
  --color-utility-button-icon-hover: var(--color-navy-600);
}
/* stylelint-enable scss/dollar-variable-pattern, color-hex-length */
