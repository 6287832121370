$font-inter: inter;
$font-weight-bold: 700;
$font-weight-semi-bold: 600;
$font-weight-regular: 400;
$font-weight-light: 300;

.v3__theme {
  --font-family: inter, "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  --font-family-bold: inter, "Helvetica Neue", "Helvetica", "Arial", sans-serif;

  --font-weight-light: #{$font-weight-light};
  --font-weight-regular: #{$font-weight-regular};
  --font-weight-bold: #{$font-weight-bold};
  --font-weight-semi-bold: #{$font-weight-semi-bold};

  --font-size-h1: 36px;
  --font-size-h2: 24px;
  --font-size-h3: 20px;
  --font-size-h4: 16px;
  --font-size-h5: 14px;
  --font-size-h6: 12px;
  --font-size-h7: 11px;

  --line-height-h1: 44px;
  --line-height-h2: 30px;
  --line-height-h3: 24px;
  --line-height-h4: 20px;
  --line-height-h5: 18px;
  --line-height-h6: 15px;

  --font-size-body: 16px;
  --font-size-small-body: 14px;
  --font-size-extra-small-body: 12px;
  --font-size-small-status-tag: 10px;
}

// == Inter ==

@font-face {
  font-weight: $font-weight-regular;
  font-family: $font-inter;
  src: url("/runner/_work/wallet/wallet/client/elm-lib/audit/client/elm/shared-ui-v3/src/Ui/V3/../../../../../../app/assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-weight: $font-weight-semi-bold;
  font-family: $font-inter;
  src: url("/runner/_work/wallet/wallet/client/elm-lib/audit/client/elm/shared-ui-v3/src/Ui/V3/../../../../../../app/assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-weight: $font-weight-bold;
  font-family: $font-inter;
  src: url("/runner/_work/wallet/wallet/client/elm-lib/audit/client/elm/shared-ui-v3/src/Ui/V3/../../../../../../app/assets/fonts/Inter-Bold.ttf");
}

.v3__type {
  margin-bottom: 0;
  color: $color-grayscale-900;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-body);
  font-family: var(--font-family);
  line-height: 1.5em;
}

a.v3__type {
  color: $color-brand-600;

  &:hover,
  &:focus,
  &:active {
    color: $color-brand-700;
  }

  &:focus {
    margin-right: -2px;
    margin-left: -2px;
    padding: 4px 2px;
    border-radius: var(--corner-radius-sm);
    outline: 2px solid $color-grayscale-900;
  }
}

.v3__type__color-danger {
  color: var(--color-danger-500);
}

.v3__type__color-light {
  color: $color-brand-0;
}

.v3__type__h1 {
  @extend .v3__type;

  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h1);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h1);
  letter-spacing: -1px;
}

.v3__type__h2 {
  @extend .v3__type;

  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h2);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h2);
}

.v3__type__h3 {
  @extend .v3__type;

  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h3);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h3);
}

.v3__type__h4 {
  @extend .v3__type;

  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h4);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h4);
}

.v3__type__h5 {
  @extend .v3__type;

  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h5);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h5);
}

.v3__type__new_h5 {
  @extend .v3__type__h5;

  // new code should use .v3__type__h5 instead of .v3__type__new_h5
}

.v3__type__h6 {
  @extend .v3__type;

  margin: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h6);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h6);
}

.v3__type__new_h6 {
  @extend .v3__type__h6;

  // new code should use .v3__type__h6 instead of .v3__type__new_h6
}

.v3__type__body {
  @extend .v3__type;
}

.v3__type__body-semi-bold {
  @extend .v3__type;

  font-weight: var(--font-weight-semi-bold);
}

.v3__type__small-body {
  @extend .v3__type;

  font-size: var(--font-size-small-body);
}

.v3__type__extra-small-body {
  @extend .v3__type;

  font-size: var(--font-size-extra-small-body);
}

.v3__type__small-body-semi-bold {
  @extend .v3__type;

  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-small-body);
}

.v3__type__extra-small-body-semi-bold {
  @extend .v3__type;

  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-extra-small-body);
}

h1 {
  @extend .v3__type__h1;
}

h2 {
  @extend .v3__type__h2;
}

h3 {
  @extend .v3__type__h3;
}

h4 {
  @extend .v3__type__h4;
}

h5 {
  @extend .v3__type__h5;
}

h6 {
  @extend .v3__type__h6;
}

p {
  @extend .v3__type;
}
