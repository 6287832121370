.v3__icon--spin {
  display: inline-block;
  animation: spin-animation 2s linear infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
