.v3__progress {
  height: 8px;
  background: #e6e2de;
  border-radius: 50px;
}

.v3__progress__fill {
  height: 100%;
  background: $color-brand-600;
  border-radius: 50px;
}
