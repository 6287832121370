/* Remove the border, margin, and shadow from the Okta widget, and unset its width
  so that it fills available space */
#okta-sign-in.auth-container.main-container {
  margin: unset;
  width: unset;
  box-shadow: unset;
  border: 0;
  font-family: inter;
}

#okta-sign-in.auth-container .auth-content {
  /* okta has some weird responsive behavior around media types, so use important */
  max-width: unset !important;
}

#okta-sign-in {
  overflow: visible;
}

#okta-sign-in.auth-container .beacon-container {
  display: none;
}

/* Reduce horizontal padding as much as possible - 6px is needed to avoid
  clipping the glow effect around the focused element */
#okta-sign-in .auth-content {
  padding: 0px;
}

#okta-sign-in .o-form-head {
  margin-top: 0;
}

#okta-sign-in .o-form .o-form-fieldset:last-child {
  margin-bottom: 0;
}

#okta-sign-in .o-form-button-bar {
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  gap: $space-xs;
  align-items: baseline;
  align-content: center;
}

.v3__mobile-device {
  #okta-sign-in .o-form-button-bar {
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0px;
  }

  .siw-main-footer {
    padding-top: 32px !important;
  }
}

#okta-sign-in.auth-container .okta-sign-in-header {
  display: none;
}

#okta-sign-in .o-form-button-bar .button {
  width: unset;
}

/* Hide the "need help signing in?" section toggle */
#okta-sign-in a.link.js-help {
  display: none;
}

/* Hide the generic "help" link that navigates to the Okta portal */
#okta-sign-in .js-help-link {
  display: none;
}

/* Style the links as actual hyperlinks */
#okta-sign-in.auth-container .link:active,
#okta-sign-in.auth-container .link:hover,
#okta-sign-in.auth-container .link:link,
#okta-sign-in.auth-container .link:visited {
  color: $color-green-500 !important;
  text-decoration: none;
  font-family: inter;
  font-size: $font-size-link-small;
  font-weight: $font-weight-link-small;
  line-height: $font-height-link-small;
}

#okta-sign-in.auth-container .link:hover {
  color: $color-green-700 !important;
}

/* Remove the padding between the logo and the "Sign In" header */
#okta-sign-in.no-beacon .auth-content {
  padding-top: unset;
}

#okta-sign-in .o-form-head,
#okta-sign-in .o-form-subhead {
  margin-bottom: $space-m;
}

.v3__mobile-device {
  #okta-sign-in .o-form-head,
  #okta-sign-in .o-form-subhead {
    margin-bottom: $space-s;
  }
}

/* "Sign In" header */
#okta-sign-in .o-form-head {
  font-family: inter;
  font-size: $font-size-h2;
  font-weight: $font-weight-h2;
  line-height: $font-height-h2;
  text-align: unset;
}

.v3__mobile-device {
  #okta-sign-in .o-form-head {
    font-size: var(--font-size-h2-mobile);
    font-weight: var(--font-weight-h2-mobile);
    line-height: var(--font-height-h2-mobile);
  }
}

#okta-sign-in.okta-form-title.o-form-head {
  color: orange;
}

#okta-sign-in.auth-container h2,
#okta-sign-in.auth-container h3 {
  font-weight: 700;
  color: $color-green-900;
}

.v3__mobile-device #okta-sign-in.auth-container h3 {
  text-align: center;
}

/* Field labels */
#okta-sign-in .o-form-label {
  padding: unset;
}

#okta-sign-in .o-form-label label {
  font-family: inter;
  font-size: $font-size-label;
  font-weight: $font-weight-label;
  line-height: $font-height-label;
}

#okta-sign-in .o-form .custom-checkbox label {
  font-family: inter;
  font-size: $font-size-label;
  font-weight: $font-weight-label;
  line-height: $font-height-label;
}

/* Text boxes */
#okta-sign-in.auth-container .okta-form-input-field {
  border: none;
}

#okta-sign-in.auth-container .okta-form-input-field input,
#okta-sign-in.auth-container .okta-form-input-field input[type="password"] {
  font-family: inter;

  font-family: inter;
  font-size: $font-size-body;
  font-weight: $font-weight-body;
  line-height: $font-height-body;

  border-radius: 4px;
  border: 2px solid $color-brown-400 !important;
  transition: border-color 300ms;

  padding: 10px 10px 9px 10px !important;

  &:focus {
    border: 2px solid $color-brown-900 !important;
  }

  &:hover {
    border: 2px solid $color-brown-900 !important;
  }
}

/* Nuke the box shadow */
#okta-sign-in .focused-input,
#okta-sign-in .link.help:focus,
#okta-sign-in input[type="radio"]:focus + label,
#okta-sign-in input[type="text"]:focus {
  box-shadow: unset;
}

/* Widget separation */
#okta-sign-in.auth-container.main-container [class*="margin-btm-"] {
  margin-bottom: unset;
}

#okta-sign-in.auth-container.main-container .o-form-fieldset-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Individual widgets */
#okta-sign-in .o-form-fieldset {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
}

#okta-sign-in .o-form-fieldset.phone-authenticator-enroll__phone-ext {
  margin-left: 0;
  position: relative;
}

#okta-sign-in .o-form-button-bar .button {
  height: unset;
  /* the loading button replaces contents w/ a shorter string -
   * use a min-width to stop it from becoming small during the loading state.
   */
  min-width: 90px;
}

/* Match the button size and styling used by our components */
#okta-sign-in .o-form-button-bar .button,
#okta-sign-in .default-custom-button.link-button {
  padding: $space-xxs $space-xs;
  border-radius: $space-xxs;
  font-family: inter;
  font-size: $font-size-body;
  font-weight: $font-weight-body;
  line-height: $font-height-body;
}

#okta-sign-in.auth-container input[type="button"],
#okta-sign-in.auth-container input[type="submit"] {
  font-family: inter;
  font-size: $font-size-body;
  font-weight: $font-weight-body;
  line-height: $font-height-body;
}

#okta-sign-in.auth-container .button-primary {
  background: $color-green-500;
  border: none;
}

#okta-sign-in.auth-container .button.button-primary.link-button-disabled {
  background: $color-green-300;
}

#okta-sign-in.auth-container .button-primary:active,
#okta-sign-in.auth-container .button-primary:focus,
#okta-sign-in.auth-container .button-primary:hover {
  background: $color-green-400;
}

.v3__mobile-device .okta-user-sign-in-page {
  padding: $space-s;
}
