.v3__modal {
  position: fixed;
  top: 50vh;
  left: 50vw;
  display: flex;
  box-sizing: border-box;
  max-width: 92%;
  max-height: 90vh;
  padding: $space-s;
  text-align: left;
  background: $color-grayscale-0;
  border-top-width: 0;
  border-radius: 0.625rem;
  box-shadow: 0 0 8px 0 rgb(0 34 56 / 0.1);
  transform: translate(-50%, -50%);

  &__xsmall-container {
    width: 324px;
  }

  &__small-container {
    width: 404px;
  }

  &__medium-container {
    width: 488px;
  }

  &__large-container {
    width: 744px;
    height: 702px;
  }

  .v3__modal__hr {
    margin: 0;
    border-top: 1px solid var(--color-brown-300);
  }

  .v3__modal__close-button {
    padding: 0;
    color: var(--color-brown-900);
  }
}

.v3__modal__bg {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: $color-overlays-scrim;
}

.v3__modal__outer_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.v3__modal__container {
  width: 100%;
  height: 100%;
  background: $color-grayscale-0;
  box-shadow: 0 0 8px 0 $color-overlays-scrim;
}
