.v3__radio-icon {
  position: relative;
  display: flex;
  align-items: center;
}

.v3__radio-input,
input.v3__radio-input /* Override for global styles outside of v3 CSS */ {
  position: absolute;
  opacity: 0;
}

.v3__icon-radio-outer {
  stroke: $color-grayscale-400;
}

.v3__icon-radio-inner {
  fill: $color-brand-600;
}

.v3__radio-icon--error {
  .v3__icon-radio-outer {
    stroke: $color-danger-600;
  }

  .v3__icon-radio-inner {
    fill: $color-danger-600;
  }
}

.v3__radio-icon--disabled {
  cursor: auto;

  .v3__icon-radio-outer {
    fill: $color-grayscale-300;
  }

  .v3__icon-radio-inner {
    fill: $color-grayscale-500;
  }
}

.v3__radio-label-text {
  font-size: var(--font-size-extra-small-body);
}
