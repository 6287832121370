// This can be used to reset all button styles in cases where you need to
// use an <a> element for other purposes
@mixin v3-a-reset {
  display: block;
  color: inherit; /* stylelint-disable-line scale-unlimited/declaration-strict-value */
  text-decoration: none;
}

.v3__link {
  color: var(--color-blue-700);
  font-weight: var(--font-weight-bold);
  text-decoration: underline;
  border-radius: calc(1em / 4);
  text-underline-offset: 2px;

  &:active {
    color: var(--color-blue-600);
  }

  &:focus {
    z-index: 1; // this ensures the offset outline is not hidden by adjacent elements
    outline: solid 2px var(--color-grayscale-900);
    outline-offset: 3px;
  }

  &:hover {
    color: var(--color-blue-800);
  }
}

.v3__link__plain {
  @include v3-a-reset;
}
