.v3__key-value-pairs {
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: var(--font-size-h6);
  line-height: 1.5em;
}

.v3__key-value-pairs--horizontal {
  flex-direction: row;
  column-gap: var(--space-s);
  row-gap: var(--space-xxs);

  .v3__key-value-pairs-group {
    display: flex;
    flex-direction: column;
    gap: var(--space-xxs);
  }
}

.v3__key-value-pairs-key,
.v3__key-value-pairs-value {
  &:empty::before {
    content: "\a0"; // non-breaking space, prevents empty element from collapsing
  }
}

.v3__key-value-pairs-key {
  font-weight: var(--font-weight-regular);
}

.v3__key-value-pairs-value {
  font-weight: var(--font-weight-bold);
}
