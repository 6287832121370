$font-size-subheader: 14px;

.v3__card {
  position: relative;
  background: $color-background-container;
  border: solid 1px $color-grayscale-300;
  border-radius: var(--corner-radius-m);
}

.v3__card-header {
  @extend .v3__type__h4;

  display: flex;
  gap: var(--space-xs);
  align-items: center;

  .v3__card-header-space {
    flex: 1;
  }
}

.v3__card-footer {
  & .v3__grid {
    justify-content: end;
  }
}

.v3__card-header,
.v3__card-body,
.v3__card-footer {
  padding: var(--space-xs) var(--space-s);

  &:first-child {
    padding-top: var(--space-s);
    border-top-left-radius: var(--corner-radius-m);
    border-top-right-radius: var(--corner-radius-m);
  }

  &:last-child {
    padding-bottom: var(--space-s);
    overflow: hidden;
    border-bottom-right-radius: var(--corner-radius-m);
    border-bottom-left-radius: var(--corner-radius-m);
  }
}

.v3__card-body__no-padding {
  padding: 0;

  &:last-child {
    padding-bottom: 0;
  }
}

.v3__card-body__visible-overflow {
  overflow: visible;

  &:last-child {
    overflow: visible;
  }
}

.v3__card-subheader {
  @extend .v3__type__h6;

  font-size: $font-size-subheader;
  font-family: var(--font-family);
  line-height: 16px;
}

.v3__card-divider {
  margin: 0;
  padding: 0;
  border: solid $color-grayscale-300;
  border-width: 1px 0 0;
}

.v3__card > .v3__card-divider {
  margin: 0 var(--space-s);
}

.v3__card .v3__tabs {
  .v3__tabs__tab {
    border-top: none;
  }

  .v3__tabs__tab:first-of-type {
    border-left: none;
  }

  .v3__tabs__tab:last-of-type {
    border-right: none;
  }
}
