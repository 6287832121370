$element-height: 30px;

.v3-pagination {
  display: flex;
  gap: var(--space-m);
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px 20px;
}

.v3-pagination__element {
  display: flex;
  align-items: center;
  height: $element-height;
}

// "Rows per page" select

.v3-pagination__per-page-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--space-xxs);
}

.v3-pagination__per-page-label {
  color: $color-grayscale-900;
  white-space: nowrap;
}

.v3-pagination__per-page-select {
  &.v3__simple-select {
    &#{&} {
      min-width: 60px;
      height: $element-height;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.v3-pagination__right {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  column-gap: var(--space-m);
}

.v3-pagination__results {
  white-space: nowrap;
}

// arrows
.v3-pagination__arrow-container {
  display: flex;
  gap: var(--space-m);
}

.v3-pagination__arrow-group {
  display: flex;
  flex: 0;
  gap: var(--space-xs);
}

button.v3-pagination__arrow {
  &.v3-pagination__arrow {
    // specificity hack to override foundation.css styles
    display: block;
    margin: 0;
    padding: 0;
    color: $color-grayscale-900;
    background: none;
    border: none;
    opacity: initial;
    transition: none;
  }

  &:disabled {
    color: $color-grayscale-400;
  }

  & > svg {
    display: block;
  }
}
