.v3__tabs__tab,
button.v3__tabs__tab /* Override for global styles outside of v3 CSS */ {
  margin: 0;
  padding: var(--space-xxs) var(--space-xxs);
  color: $color-grayscale-900;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h6);
  cursor: pointer;
  transition: none;
}

.v3__tabs--presentation {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top-left-radius: var(--corner-radius-m);
  border-top-right-radius: var(--corner-radius-m);

  .v3__tabs__tab {
    flex: 1;
    line-height: calc(1em + 2px);
    background-color: $color-grayscale-100;
    border: 1px solid $color-grayscale-400;
    border-right-width: 0;

    &:first-of-type {
      border-top-left-radius: var(--corner-radius-m);
    }

    &:last-of-type {
      border-right-width: 1px;
      border-top-right-radius: var(--corner-radius-m);
    }

    &:hover {
      background-color: $color-grayscale-300;
    }

    &:active {
      background-color: $color-grayscale-400;
    }

    &:focus {
      z-index: 1; // this ensures the offset outline is not hidden by adjacent elements
      outline-color: $color-grayscale-900;
      outline-offset: 3px;
    }
  }

  .v3__tabs__tab--selected {
    background-color: $color-grayscale-0;
    box-shadow: inset 0 -4px 0 $color-brand-600;

    &:hover {
      background-color: $color-grayscale-0;
    }
  }
}

.v3__tabs__name-wrapper {
  display: flex;
  gap: var(--space-xxxs);
  justify-content: center;
  width: 100%;
}
