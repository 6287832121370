@mixin tag-color($color) {
  background-color: $color;
  border: solid 2px $color;

  &.v3__status-tag--outlined {
    background-color: transparent;
  }
}

.v3__status-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 2px 10px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h6);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h6);
  white-space: nowrap;
  vertical-align: text-bottom;
  border-radius: 62px;
}

.v3__status-tag--color-success {
  @include tag-color(var(--color-success-400));
}

.v3__status-tag--color-info {
  @include tag-color(var(--color-blue-200));
}

.v3__status-tag--color-warning {
  @include tag-color(var(--color-warning-400));
}

.v3__status-tag--color-alert {
  @include tag-color(var(--color-danger-400));
}

.v3__status-tag--color-default {
  @include tag-color(var(--color-grayscale-300));
}

.v3__status-tag--size-small {
  height: 16px;
  padding: 0 6px;
  font-size: var(--font-size-small-status-tag);
}
