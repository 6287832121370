.v3__skeleton {
  background: $color-navy-300;
  overflow: hidden;
  position: absolute;
  border-radius: 12px;
}

.v3__skeleton--image {
  z-index: 1;
  position: relative;
}

.v3__skeleton--standalone {
  position: relative;
}

.v3__skeleton:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0) 70%
  );
  animation: shimmer cubic-bezier(0.7, 0, 0.3, 1) 2s infinite;
  content: "";
}

@keyframes shimmer {
  0% {
    transform: translateX(-70%);
  }
  100% {
    transform: translateX(70%);
  }
}
