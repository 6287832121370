.header__menu-btn {
  &:hover {
    background: $color-blue-800;
  }
}

.header__menu-btn--open {
  background: $color-blue-800;

  &:hover {
    background: $color-blue-700;
  }
}

// reset
.v3__header {
  h4,
  h5 {
    color: $color-grayscale-0;
  }
}
