.v3__grid {
  display: flex;
  flex-wrap: wrap;
  align-content: start;
  justify-content: center;
  box-sizing: border-box; // ensures that padding calculations don't interfere with flex
  width: 100%;
}

// Gap

.v3__grid--gap-xxxs {
  gap: var(--space-xxxs);
}

.v3__grid--gap-xxs {
  gap: var(--space-xxs);
}

.v3__grid--gap-xs {
  gap: var(--space-xs);
}

.v3__grid--gap-s {
  gap: var(--space-s);
}

.v3__grid--gap-m {
  gap: var(--space-m);
}

.v3__grid--gap-l {
  gap: var(--space-l);
}

.v3__grid--gap-xl {
  gap: var(--space-xl);
}

.v3__grid--gap-xxl {
  gap: var(--space-xxl);
}

.v3__grid--gap-xxxl {
  gap: var(--space-xxxl);
}

// Padding

.v3__grid--padding-xxxs {
  padding: var(--space-xxxs);
}

.v3__grid--padding-xxs {
  padding: var(--space-xxs);
}

.v3__grid--padding-xs {
  padding: var(--space-xs);
}

.v3__grid--padding-s {
  padding: var(--space-s);
}

.v3__grid--padding-m {
  padding: var(--space-m);
}

.v3__grid--padding-l {
  padding: var(--space-l);
}

.v3__grid--padding-xl {
  padding: var(--space-xl);
}

.v3__grid--padding-xxl {
  padding: var(--space-xxl);
}

.v3__grid--padding-xxxl {
  padding: var(--space-xxxl);
}

// Flexible widths

.v3__grid-column--width-flex-1 {
  flex: 1;
  max-width: 100%;
}

.v3__grid-column--width-flex-2 {
  flex: 2;
  max-width: 100%;
}

.v3__grid-column--width-flex-3 {
  flex: 3;
  max-width: 100%;
}

// Fixed widths

.v3__grid-column--width-fit {
  width: fit-content;
}

.v3__grid-column--width-container-xxs {
  width: 100%;
  max-width: var(--container-xxs);
}

.v3__grid-column--width-container-xs {
  width: 100%;
  max-width: var(--container-xs);
}

.v3__grid-column--width-container-s {
  width: 100%;
  max-width: var(--container-s);
}

.v3__grid-column--width-container-m {
  width: 100%;
  max-width: var(--container-m);
}

.v3__grid-column--width-container-l {
  width: 100%;
  max-width: var(--container-l);
}

.v3__grid-column--width-container-xl {
  width: 100%;
  max-width: var(--container-xl);
}

// Override for global styles outside of v3 CSS
//    [class*="column"] + [class*="column"]:last-child { float: right }

[class*="v3__grid-column"] + [class*="v3__grid-column"]:last-child {
  float: none;
}
