// Designs: https://marvelapp.com/prototype/c80dhd6/screen/84586018/handoff

.v3__theme {
  --container-xl: 1136px;
  --container-l: 944px;
  --container-m: 752px;
  --container-s: 560px;
  --container-xs: 368px;
  --container-xxs: 272px;
  --topbar-height: 64px;
}

// Mixin for responsive design with container-s
// (media queries do not support custom properties)
@mixin v3-container-mobile {
  @media (max-width: 560px) {
    @content;
  }
}

.v3__page_container {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  background-color: var(--color-navy-100);
}
