// * DO NOT EDIT MANUALLY. Code generated by `rake images:generate`

.v3--image {
  background-size: contain;
  background-repeat: no-repeat;
}

.v3--image__a_to_b {
  background-image: url(../../../app/assets/images/icons/a_to_b.svg);
  width: 94px;
  height: 28px;
}

.v3--image__ach_default {
  background-image: url(../../../app/assets/images/icons/ACH_Default.svg);
  width: 41px;
  height: 41px;
}

.v3--image__ach_hover {
  background-image: url(../../../app/assets/images/icons/ACH_Hover.svg);
  width: 41px;
  height: 41px;
}

.v3--image__ach_selected {
  background-image: url(../../../app/assets/images/icons/ACH_Selected.svg);
  width: 41px;
  height: 41px;
}

.v3--image__add_to_apple_wallet {
  background-image: url(../../../app/assets/images/icons/Add_to_Apple_Wallet.svg);
  width: 111px;
  height: 35px;
}

.v3--image__add_to_google_wallet {
  background-image: url(../../../app/assets/images/icons/Add_to_Google_Wallet.svg);
  width: 199px;
  height: 55px;
}

.v3--image__apple_pay {
  background-image: url(../../../app/assets/images/icons/apple-pay.svg);
  width: 38px;
  height: 25px;
}

.v3--image__card_mockup {
  background-image: url(../../../app/assets/images/icons/card-mockup.svg);
  width: 500px;
  height: 315px;
}

.v3--image__cards {
  background-image: url(../../../app/assets/images/icons/Cards.svg);
  width: 32px;
  height: 32px;
}

.v3--image__cards_off {
  background-image: url(../../../app/assets/images/icons/cards-off.svg);
  width: 24px;
  height: 25px;
}

.v3--image__cards_on {
  background-image: url(../../../app/assets/images/icons/cards-on.svg);
  width: 24px;
  height: 25px;
}

.v3--image__ch_robinson_logo {
  background-image: url(../../../app/assets/images/icons/CHRobinson-logo.svg);
  width: 125px;
  height: 33px;
}

.v3--image__check {
  background-image: url(../../../app/assets/images/icons/Check.svg);
  width: 17px;
  height: 16px;
}

.v3--image__clipboard_error {
  background-image: url(../../../app/assets/images/icons/clipboard_error.svg);
  width: 191px;
  height: 191px;
}

.v3--image__danger {
  background-image: url(../../../app/assets/images/icons/danger.svg);
  width: 129px;
  height: 129px;
}

.v3--image__debit_account_default {
  background-image: url(../../../app/assets/images/icons/debit-account-default.svg);
  width: 40px;
  height: 40px;
}

.v3--image__debit_account_hover {
  background-image: url(../../../app/assets/images/icons/debit-account-hover.svg);
  width: 40px;
  height: 40px;
}

.v3--image__debit_account_selected {
  background-image: url(../../../app/assets/images/icons/debit-account-selected.svg);
  width: 40px;
  height: 40px;
}

.v3--image__debit_card {
  background-image: url(../../../app/assets/images/icons/Debit_card.svg);
  width: 40px;
  height: 40px;
}

.v3--image__download_single {
  background-image: url(../../../app/assets/images/icons/download-single.svg);
  width: 16px;
  height: 16px;
}

.v3--image__empty {
  background-image: url(../../../app/assets/images/icons/empty.svg);
  width: 40px;
  height: 40px;
}

.v3--image__error {
  background-image: url(../../../app/assets/images/icons/error.svg);
  width: 80px;
  height: 80px;
}

.v3--image__eye_closed {
  background-image: url(../../../app/assets/images/icons/eye-closed.svg);
  width: 16px;
  height: 16px;
}

.v3--image__eye_open {
  background-image: url(../../../app/assets/images/icons/eye-open.svg);
  width: 16px;
  height: 16px;
}

.v3--image__fdic_digital_sign_blue {
  background-image: url(../../../app/assets/images/icons/fdic-digital-sign-blue.svg);
  width: 390px;
  height: 16px;
}

.v3--image__fdic_digital_sign_white {
  background-image: url(../../../app/assets/images/icons/fdic-digital-sign-white.svg);
  width: 390px;
  height: 16px;
}

.v3--image__finish_flag {
  background-image: url(../../../app/assets/images/icons/finish-flag.svg);
  width: 40px;
  height: 39px;
}

.v3--image__folder {
  background-image: url(../../../app/assets/images/icons/folder.svg);
  width: 120px;
  height: 120px;
}

.v3--image__fuel_card {
  background-image: url(../../../app/assets/images/icons/fuel-card.svg);
  width: 40px;
  height: 40px;
}

.v3--image__fuel_card_preview {
  background-image: url(../../../app/assets/images/icons/fuel-card-preview.svg);
  width: 64px;
  height: 40px;
}

.v3--image__google_pay {
  background-image: url(../../../app/assets/images/icons/google-pay.svg);
  width: 41px;
  height: 22px;
}

.v3--image__home_off {
  background-image: url(../../../app/assets/images/icons/home-off.svg);
  width: 25px;
  height: 25px;
}

.v3--image__home_on {
  background-image: url(../../../app/assets/images/icons/home-on.svg);
  width: 25px;
  height: 25px;
}

.v3--image__instant_transfer_default {
  background-image: url(../../../app/assets/images/icons/Instant_Transfer_Default.svg);
  width: 41px;
  height: 41px;
}

.v3--image__instant_transfer_hover {
  background-image: url(../../../app/assets/images/icons/Instant_Transfer_Hover.svg);
  width: 41px;
  height: 41px;
}

.v3--image__instant_transfer_selected {
  background-image: url(../../../app/assets/images/icons/Instant_Transfer_Selected.svg);
  width: 41px;
  height: 41px;
}

.v3--image__left_arrow {
  background-image: url(../../../app/assets/images/icons/left-arrow.svg);
  width: 16px;
  height: 17px;
}

.v3--image__list_card {
  background-image: url(../../../app/assets/images/icons/list-card.svg);
  width: 17px;
  height: 13px;
}

.v3--image__list_details {
  background-image: url(../../../app/assets/images/icons/list-details.svg);
  width: 17px;
  height: 17px;
}

.v3--image__list_lock {
  background-image: url(../../../app/assets/images/icons/list-lock.svg);
  width: 17px;
  height: 16px;
}

.v3--image__list_pin {
  background-image: url(../../../app/assets/images/icons/list-pin.svg);
  width: 17px;
  height: 17px;
}

.v3--image__list_replace {
  background-image: url(../../../app/assets/images/icons/list-replace.svg);
  width: 17px;
  height: 16px;
}

.v3--image__load_pay_icon {
  background-image: url(../../../app/assets/images/icons/load-pay-icon.svg);
  width: 20px;
  height: 21px;
}

.v3--image__logo_color {
  background-image: url(../../../app/assets/images/icons/logo-color.svg);
  width: 122px;
  height: 31px;
}

.v3--image__logo_white {
  background-image: url(../../../app/assets/images/icons/logo-white.svg);
  width: 121px;
  height: 31px;
}

.v3--image__member_fdic {
  background-image: url(../../../app/assets/images/icons/member-fdic.png);
  width: 74px;
  height: 48px;
}

.v3--image__more_info_clipboard {
  background-image: url(../../../app/assets/images/icons/more-info-clipboard.svg);
  width: 80px;
  height: 80px;
}

.v3--image__percentage {
  background-image: url(../../../app/assets/images/icons/Percentage.svg);
  width: 16px;
  height: 16px;
}

.v3--image__plan_debit_and_fuel_card {
  background-image: url(../../../app/assets/images/icons/plan-debit-and-fuel-card.svg);
  width: 93px;
  height: 54px;
}

.v3--image__plan_debit_card {
  background-image: url(../../../app/assets/images/icons/plan-debit-card.svg);
  width: 64px;
  height: 40px;
}

.v3--image__pointer {
  background-image: url(../../../app/assets/images/icons/Pointer.svg);
  width: 12px;
  height: 7px;
}

.v3--image__profile {
  background-image: url(../../../app/assets/images/icons/profile.svg);
  width: 20px;
  height: 21px;
}

.v3--image__profile_off {
  background-image: url(../../../app/assets/images/icons/profile-off.svg);
  width: 25px;
  height: 25px;
}

.v3--image__profile_on {
  background-image: url(../../../app/assets/images/icons/profile-on.svg);
  width: 25px;
  height: 25px;
}

.v3--image__statements {
  background-image: url(../../../app/assets/images/icons/Statements.svg);
  width: 32px;
  height: 32px;
}

.v3--image__success {
  background-image: url(../../../app/assets/images/icons/success.svg);
  width: 191px;
  height: 191px;
}

.v3--image__transfer {
  background-image: url(../../../app/assets/images/icons/Transfer.svg);
  width: 32px;
  height: 32px;
}

.v3--image__triumph_pay {
  background-image: url(../../../app/assets/images/icons/TriumphPay.svg);
  width: 40px;
  height: 41px;
}

.v3--image__triumph_pay2 {
  background-image: url(../../../app/assets/images/icons/TriumphPay2.svg);
  width: 33px;
  height: 32px;
}

.v3--image__waiting {
  background-image: url(../../../app/assets/images/icons/waiting.svg);
  width: 241px;
  height: 240px;
}

.v3--image__warning {
  background-image: url(../../../app/assets/images/icons/warning.svg);
  width: 40px;
  height: 40px;
}
