.v3__breadcrumb {
  display: block;
  margin-bottom: var(--space-s);
  line-height: 1.5em;

  .v3__link {
    color: var(--color-grayscale-600);
    font-weight: var(--font-weight-regular);

    &:hover {
      color: var(--color-grayscale-700);
    }
  }
}

.v3__breadcrumb-link::after {
  padding: 0 var(--space-xxs);
  color: var(--color-blue-800);
  content: "/";
}

.v3__breadcrumb-text {
  color: var(--color-grayscale-800);
  font-weight: var(--font-weight-bold);
}

// setting font-size in this overly specific way to counteract Foundation
.v3__breadcrumb a.v3__link,
.v3__breadcrumb-link::after,
.v3__breadcrumb-text {
  font-size: var(--font-size-small-body);
}
