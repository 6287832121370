.v3__simple-select {
  &#{&} {
    height: 40px;
    padding: 8px 25px 7px 10px;
    font-size: $simple-select-font-size;

    // custom chevron to replace browser default
    background: $color-grayscale-0
      url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9IkNoZXZyb24iPgo8cGF0aCBpZD0iVmVjdG9yIiBkPSJNNS45ODQ1OCA4LjYwMDQ4QzUuNzIyNjcgOC42MDA0OCA1LjQ5ODE4IDguNDg4MjMgNS4zNDg1MSA4LjMwMTE1TDEuNjQ0MzIgNC4xNDc5N0MxLjQxOTgyIDMuODQ4NjQgMS40NTcyNCAzLjM5OTY1IDEuNzU2NTcgMy4xNzUxNUMyLjA1NTkgMi45NTA2NSAyLjQ2NzQ3IDIuOTUwNjUgMi42OTE5NyAzLjI0OTk4TDUuOTA5NzUgNi45MTY3NkM1Ljk0NzE3IDYuOTU0MTcgNS45ODQ1OCA2Ljk1NDE3IDYuMDU5NDIgNi45MTY3Nkw5LjI3NzIgMy4yNDk5OEM5LjUzOTExIDIuOTUwNjUgOS45NTA2OSAyLjkxMzI0IDEwLjI1IDMuMTc1MTVDMTAuNTQ5MyAzLjQzNzA2IDEwLjU4NjggMy44NDg2NCAxMC4zMjQ5IDQuMTQ3OTdDMTAuMzI0OSA0LjE0Nzk3IDEwLjMyNDkgNC4xNDc5NyAxMC4yODc0IDQuMTg1MzhMNi42MjA2NiA4LjMzODU3QzYuNDcwOTkgOC40ODgyMyA2LjIwOTA4IDguNjAwNDggNS45ODQ1OCA4LjYwMDQ4WiIgZmlsbD0iIzA0MTgyNSIvPgo8L2c+Cjwvc3ZnPgo=");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
    appearance: none;
  }
}

.v3__simple-select--with-disabled-option {
  &#{&} {
    color: $color-grayscale-500;
  }
}
