.v3__utility-button {
  border-radius: 4px;
  border: 2px solid;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.v3__utility-button--large {
  width: 40px;
  height: 40px;

  > svg {
    // icons svgs are hard-coded to 16px.  Scale them up
    transform: scale(calc(20 / 16));
  }
}

.v3__utility-button--small {
  width: 32px;
  height: 32px;
  // icons svgs are already hard-coded to 16px.  Don't scale them
}

.v3__utility-button--light {
  color: $color-brown-600;
  border-color: $color-brown-400;
  background-color: $color-grayscale-0;
  &:hover {
    border-color: $color-green-600;
    background-color: $color-brown-200;
  }

  &:active {
    border-color: $color-green-600;
    background-color: $color-brown-400;
  }
}

.v3__utility-button--dark {
  color: $color-grayscale-0;
  border-color: $color-green-600;
  background-color: $color-green-600;

  &:hover {
    border-color: $color-green-600;
    background-color: $color-green-600;
  }

  &:active {
    border-color: $color-green-700;
  }
}
