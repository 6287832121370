.v3__drawer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}

.v3__drawer__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $color-overlays-scrim;
}

.v3__drawer__outer_container {
  position: fixed;
  right: 0;
  height: 100%;
}

.v3__drawer__container {
  width: 100%;
  height: 100%;
  background: $color-grayscale-0;
  box-shadow: 0 0 8px 0 $color-overlays-scrim;
}

.v3__drawer__section {
  padding: $space-s;
}

.v3__drawer__divider {
  height: 3px;
  box-shadow: 0 -8px 7px -11px $color-overlays-scrim inset;
}

.v3__drawer__outer_container > .v3__stack-cell {
  // forces the outer_container to not scroll, so that the inner content can scroll
  height: 100%;
}
