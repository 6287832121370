.v3__segmented-button-group {
  display: flex;
}

.v3__segmented-button-group--inline {
  display: inline-flex;
}

.v3__segmented-button-group__button {
  display: flex;
  align-items: center;
  height: 24px;
  margin: 0;
  padding: 0 8px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h6);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h6);
  border-style: solid;
  border-width: 2px 0;
  border-width: 2px 1px;
  border-radius: 0;

  &:first-of-type {
    border-left-width: 2px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-of-type {
    border-right-width: 2px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.v3__segmented-button-group--disabled {
  .v3__segmented-button-group__button {
    color: var(--color-button-secondary-text-disabled);
    border-color: var(--color-border-disabled);
  }

  .v3__segmented-button-group__button--selected {
    background: var(--color-border-disabled);
  }

  .v3__segmented-button-group__button--unselected {
    background: var(--color-grayscale-0);
  }
}

.v3__segmented-button-group--enabled {
  .v3__segmented-button-group__button {
    border-color: var(--color-brand-600);
    cursor: pointer;
  }

  .v3__segmented-button-group__button--selected {
    color: var(--color-grayscale-0);
    background: var(--color-brand-600);

    &:hover {
      background: var(--color-button-primary-pressed);
    }
  }

  .v3__segmented-button-group__button--unselected {
    color: var(--color-brand-600);
    background: var(--color-grayscale-0);

    &:hover {
      color: var(--color-button-primary-hover);
      background: var(--color-utility-button-fill-hover);
    }

    &:active {
      color: var(--color-button-primary-pressed);
      background: var(--color-grayscale-0);
    }
  }
}
