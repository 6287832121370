$toggle-height-small: 16px;
$toggle-height-large: 24px;
$toggle-border-small: $color-grayscale-450;
$toggle-border-large: $color-grayscale-500;
$transition-duration: 0.2s;

.v3__toggle__input {
  display: none;
}

/* label background */

.v3__toggle__label {
  display: inline-block;
  box-sizing: border-box;
  line-height: 0;
  text-align: left;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  transition: background $transition-duration;
}

.v3__toggle__label--on {
  background: $color-brand-600;
}

.v3__toggle__label--off {
  &.v3__toggle__label--small {
    background: $color-grayscale-400;
  }

  &.v3__toggle__label--large {
    background: $color-grayscale-300;
  }
}

.v3__toggle__label--small {
  width: 28px;
  height: $toggle-height-small;
  border-color: $toggle-border-small;
  border-radius: $toggle-height-small;
}

.v3__toggle__label--large {
  width: 42px;
  height: $toggle-height-large;
  border-color: $toggle-border-large;
  border-radius: $toggle-height-large;
}

/* slider thumb */

.v3__toggle__slider {
  /* nudge sliders by 1px to overlap the parent border */
  position: relative;
  top: -1px;
  left: -1px;
  display: inline-block;
  box-sizing: border-box;
  height: calc(100% + 2px);
  background: white;
  border-style: solid;
  border-width: 1px;
  border-radius: 100%;
  aspect-ratio: 1 / 1;
  transition: transform $transition-duration;
}

.v3__toggle__slider--on {
  &.v3__toggle__slider--small {
    transform: translateX(12px);
  }

  &.v3__toggle__slider--large {
    transform: translateX(18px);
  }
}

.v3__toggle__slider--small {
  border-color: $toggle-border-small;
}

.v3__toggle__slider--large {
  border-color: $toggle-border-large;
}
