// The math here turns into:
// 1024 retina px is mobile, 1025 retina px is desktop, no gaps.
@mixin when-on-mobile {
  @media only screen and (max-width: 64em) {
    @content;
  }
}

@mixin when-on-desktop {
  @media only screen and (min-width: 64.01em) {
    @content;
  }
}

.show-on-desktop {
  @include when-on-mobile {
    display: none;
  }
}

.show-on-mobile {
  @include when-on-desktop {
    display: none;
  }
}
