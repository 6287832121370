@mixin badge-color($color) {
  .v3__badge-count {
    background-color: $color;
  }
}

@mixin font-size($fontSize) {
  font-size: $fontSize;

  .v3__badge-count {
    min-width: $fontSize;
    height: $fontSize;
    font-size: calc($fontSize * 0.75);
  }
}

.v3__badge--size-default {
  @include font-size(var(--font-size-body));
}

.v3__badge--size-medium {
  @include font-size(var(--font-size-h5));
}

.v3__badge--size-small {
  @include font-size(var(--font-size-h6));
}

.v3__badge {
  display: inline-flex;
  gap: var(--space-xxxs);
  align-items: center;
  color: var(--color-navy-900);
  font-weight: var(--font-weight-bold);
  line-height: 1em;
}

.v3__badge-count {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--space-xxs);
  line-height: 1em;
  border-radius: 8px;
}

.v3__badge-count--single-digit {
  padding: 0;
}

.v3__badge-title {
  font-family: var(--font-family-bold);
  line-height: 16px;
}

.v3__badge--default {
  @include badge-color(var(--color-grayscale-300));
}

.v3__badge--info {
  @include badge-color(var(--color-blue-200));
}

.v3__badge--alert {
  @include badge-color(var(--color-danger-400));
}

.v3__badge--warning {
  @include badge-color(var(--color-warning-400));
}

.v3__badge--success {
  @include badge-color(var(--color-success-400));
}

.v3__button--label-wrapper {
  position: relative;
}

.v3__button--label-wrapper .v3__badge {
  position: absolute;
  bottom: 89%;
  left: 102%;
}
