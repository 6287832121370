.v3__stack {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; // ensures that padding calculations don't interfere with flex
  width: 100%;
}

.v3__stack--box-shadow {
  box-shadow: rgb(0 34 56 / 0.1) 0 0 8px 0;
}

.v3__stack--no-wrap {
  flex-wrap: nowrap;
}

.v3__stack--horz {
  align-items: start;
}

.v3__stack--vert {
  flex-flow: column nowrap;
  align-items: stretch;
}

.v3__stack--clickable {
  cursor: pointer;
}

.v3__stack-cell {
  display: flex;
}

.v3__stack-cell > * {
  flex: 1;
}

// Cell width/heights

$flex-range: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@each $i in $flex-range {
  .v3__stack-cell--size-flex-#{$i} {
    flex: #{$i};
    max-width: 100%;
  }
}

@each $i in $flex-range {
  .v3__stack--vert > .v3__stack-cell--size-flex-#{$i} {
    overflow-y: auto;
  }
}

.v3__stack-cell--size-fit {
  flex-basis: auto;
  max-width: 100%;
}

.v3__stack-cell--size-truncate-ellipsis {
  flex: 1;
}

.v3__stack-cell--size-truncate-ellipsis,
.v3__stack-cell--size-truncate-ellipsis * {
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.v3__stack--cross-axis-align-stretch {
  align-items: stretch;
}

.v3__stack--cross-axis-align-center {
  align-items: center;
}

.v3__stack--cross-axis-align-left {
  align-items: start;
}

.v3__stack--cross-axis-align-right {
  align-items: end;
}

.v3__stack--cross-axis-align-top {
  align-items: start;
}

.v3__stack--cross-axis-align-bottom {
  align-items: end;
}

.v3__stack--cross-axis-align-baseline {
  align-items: baseline;
}

.v3__stack--main-axis-start {
  justify-content: flex-start;
}

.v3__stack--main-axis-end {
  justify-content: flex-end;
}

.v3__stack--main-axis-center {
  justify-content: center;
}

.v3__stack--main-axis-space-between {
  justify-content: space-between;
}

.v3__stack--main-axis-space-around {
  justify-content: space-around;
}

.v3__stack--main-axis-space-evenly {
  justify-content: space-evenly;
}

.v3__stack--style-as-card {
  position: relative;
  gap: $space-xs;
  padding: var(--space-responsive-s);
  background-color: $color-background-container;
  border: solid 1px $color-brown-300;
  border-radius: 8px;
}

.v3__stack--style-as-card-list {
  gap: 0;

  // style-as-card-list rounds the top and bottom element.
  // We need the root padding to be 0,
  // and instead, the child elements need to have the padding
  padding: 0;

  &:first-child {
    border-top-left-radius: var(--corner-radius-m);
    border-top-right-radius: var(--corner-radius-m);
  }

  &:last-child {
    border-bottom-right-radius: var(--corner-radius-m);
    border-bottom-left-radius: var(--corner-radius-m);
  }
}

.v3__stack--style-as-card--hoverable:hover {
  border-color: $color-navy-500;
  box-shadow: inset 0 0 0 1px $color-navy-500;
}

.v3__stack--style-as-label {
  cursor: pointer;
}

.v3__stack--style-as-ul {
  // reset user agent <ul> styles
  padding-inline-start: 0;
}

$spaces-list: (
  xxxs: $space-xxxs,
  xxs: $space-xxs,
  xs: $space-xs,
  responsive-s: $space-responsive-s,
  s: $space-s,
  m: $space-m,
  l: $space-l,
  xl: $space-xl,
  xxl: $space-xxl,
  xxxl: $space-xxxl,
);

.v3__stack--background-on-hover {
  &:hover {
    // --hover-color is set by maybeBackgroundOnHover
    // this line is just to appease linter
    --hover-color: transparent;

    background-color: var(--hover-color);
  }
}

.v3__stack-cell--size-divider {
  align-self: stretch;
  width: 2px;
  background-color: $color-brown-300;
}

.v3__stack--vert > .v3__stack-cell--size-divider {
  width: 100%;
  height: 1px;
  background-color: $color-brown-300;
}

@each $key, $val in $spaces-list {
  .v3__stack--gap-#{$key} {
    gap: $val;
  }

  .v3__stack--padding-p-#{$key} {
    padding: #{$val};
  }

  .v3__stack--padding-pl-#{$key} {
    padding-left: #{$val};
  }

  .v3__stack--padding-pr-#{$key} {
    padding-right: #{$val};
  }

  .v3__stack--padding-pt-#{$key} {
    padding-top: #{$val};
  }

  .v3__stack--padding-pb-#{$key} {
    padding-bottom: #{$val};
  }

  .v3__stack--padding-pv-#{$key} {
    padding-top: #{$val};
    padding-bottom: #{$val};
  }

  .v3__stack--padding-ph-#{$key} {
    padding-right: #{$val};
    padding-left: #{$val};
  }
}
