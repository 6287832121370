.v3__tooltip--above {
  transform: translateY(calc(-100% - 5px));
}

.v3__tooltip--below {
  transform: translateY(35px);
}

.v3__tooltip--left {
  left: 0;
  transform: translate(-110%, -8px);
}

.v3__tooltip--right {
  right: -5px;
  transform: translate(100%, -8px);
}

.v3__tooltip--wrapper {
  position: relative;

  &:not(:hover) {
    .v3__tooltip--left {
      display: none;
    }

    .v3__tooltip--right {
      display: none;
    }

    .v3__tooltip--above {
      display: none;
    }

    .v3__tooltip--below {
      display: none;
    }
  }
}

.v3__tooltip {
  position: absolute;
  z-index: 1;
  padding: 8px;
  color: $color-brand-0;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-h6);
  line-height: 18px;
  background-color: $color-grayscale-900;
  border-radius: 4px;
}

.v3__tooltip::before {
  position: absolute;
  border: 5px solid;
  content: "";
}

.v3__tooltip--header {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-h6);
  line-height: 18px;
}

.v3__tooltip--above::before {
  top: 100%;
  border-color: #00263e #0000 #0000;
}

.v3__tooltip--above.v3__tooltip--beginning::before {
  left: 15%;
}

.v3__tooltip--above.v3__tooltip--middle::before {
  left: 48%;
}

.v3__tooltip--above.v3__tooltip--end::before {
  left: 80%;
}

.v3__tooltip--below::before {
  top: -10px;
  border-color: #0000 #0000 #00263e;
}

.v3__tooltip--below.v3__tooltip--beginning::before {
  left: 15%;
}

.v3__tooltip--below.v3__tooltip--middle::before {
  left: 48%;
}

.v3__tooltip--below.v3__tooltip--end::before {
  left: 80%;
}

.v3__tooltip--left::before {
  right: -10px;
  border-color: #0000 #0000 #0000 #00263e;
  transform: translateY(-4px);
}

.v3__tooltip--left.v3__tooltip--beginning::before {
  top: 25%;
}

.v3__tooltip--left.v3__tooltip--middle::before {
  top: 48%;
}

.v3__tooltip--left.v3__tooltip--end::before {
  top: 70%;
}

.v3__tooltip--right::before {
  left: -10px;
  border-color: #0000 #00263e #0000 #0000;
  transform: translateY(-4px);
}

.v3__tooltip--right.v3__tooltip--beginning::before {
  top: 25%;
}

.v3__tooltip--right.v3__tooltip--middle::before {
  top: 48%;
}

.v3__tooltip--right.v3__tooltip--end::before {
  top: 70%;
}
