.inline {
  /* blocks seems to frequently inherit line-height, then use the max line-height of themselves or their children
  * since we have line-height set in our Text.* containers, we can kill this + depend on setting line-heigh as-needed
  */
  line-height: 0;
}

.inline--gap-xxxs > * + * {
  margin-left: var(--space-xxxs);
}
.inline--gap-xxs > * + * {
  margin-left: var(--space-xxs);
}
.inline--gap-xs > * + * {
  margin-left: var(--space-xs);
}
.inline--gap-s > * + * {
  margin-left: var(--space-s);
}
.inline--gap-m > * + * {
  margin-left: var(--space-m);
}
.inline--gap-l > * + * {
  margin-left: var(--space-l);
}
.inline--gap-xl > * + * {
  margin-left: var(--space-xl);
}
.inline--gap-xxl > * + * {
  margin-left: var(--space-xxl);
}
.inline--gap-xxxl > * + * {
  margin-left: var(--space-xxxl);
}
.inline--gap-xxxxl > * + * {
  margin-left: var(--space-xxxxl);
}
