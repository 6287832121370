$document-browser-thumbnail-height: 100px;
@import "mobile";

$brand-navy: #00263e;
$blackish: rgb(26 39 43);
$dark-gray: rgb(46 59 66);
$dark-grey: $dark-gray;
$inactive-gray: rgb(158 165 169);
$inactive-gray: $inactive-gray;
$inactive-grey: $inactive-gray;
$neutral-gray: rgb(233 235 234);
$neutral-gray: $neutral-gray;
$neutral-grey: $neutral-gray;
$near-white: rgb(245 245 245);
$highlight: #cce3ed;
$medium-highlight: #99c8dc;
$light-highlight: #e5f1f6;
$caution: rgb(241 90 36);
$light-caution: rgb(253 222 213);
$progress: rgb(163 207 98);
$medium-progress: rgb(187 213 154);
$light-progress: rgb(237 245 224);
$success-color: #66973b;
$success-button-bg-hover: #94b676;
$primary-button: #00263e;
$primary-button-hover: #0076a8;
$primary-button-active: #003b54;
$sub-nav-active-bg: $primary-button;
$is-active-color: white;
$is-complete-color: green;
$is-incomplete-color: orange;
$guessed-color: gray;
$browser-state-color: #888;
$browser-header-color: white;
$browser-background-color: #0072ce;
$browser-link-color: white;
$browser-ignored-background-color: white;
$browser-pile-link-color: white;
$header-current-color: white;
$header-component-selection-border-color: #9ea5a9;
$thumbnail-container-bg-color: white;
$header-component-selection-active-color: white;
$header-component-selection-disabled-color: white;
$header-documents-color: white;

// TODO: Check Figma to see what sizes we actually want to use here.
$font-size-lg: 1.3em;
$font-size-lgg: 1.5rem;
$font-size-lggg: 1.9em;
$font-size-lgggg: 2em;
$font-size-lggggg: 5em;
$font-size-sm-px: 12px;
$font-size-md-px: 14px;
$font-size-lg-px: 19px;

.document-browser {
  &__toggle-docs-link {
    margin-left: 20px;
    font-size: var(--font-size-h4);
    text-decoration: underline;
  }

  &__sub-nav {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 6px 10px;
    padding-bottom: 4px;
    border-top: 1px solid $highlight;
    border-bottom: 1px solid $highlight;
  }

  &__illegible-button {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-around;
    margin: inherit;
    padding: 6px 5px 6px 10px;
  }

  &__visibility-off {
    margin-right: 2px;
  }

  &__pod-status {
    padding-right: 4px;
    overflow-wrap: break-word;

    em {
      color: $color-grayscale-500;
      font-size: var(--font-size-body);
    }
  }

  #{&}__pod-status-checkbox {
    margin-right: 7px;
    margin-bottom: 0;
  }

  &__save-annotations-button {
    float: right;
    margin-bottom: 0;
    padding: 5px 10px;
    font-size: var(--font-size-body);
  }

  &__text-annotation-dialogue {
    padding: 10px;
  }

  &__text-annotation-textarea {
    margin-bottom: 1rem;
    padding: 0.5rem;
  }

  &__client-or-debtor-status {
    flex-shrink: 0;
    padding: 6px 10px;
    padding-bottom: 4px;
    border-bottom: 1px solid $highlight;

    em {
      color: $color-grayscale-500;
      font-size: var(--font-size-body);
    }
  }

  &__assign-documents-selector {
    max-height: 14rem;
    padding: 6px 10px;
    padding-bottom: 4px;
    overflow-y: scroll;

    /* border-top: 1px solid $highlight; */
    border-bottom: 1px solid $highlight;
  }

  &__popout-annotation-canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
  }

  &__list-item--batched--primary {
    .document-thumbnail__title {
      background: $brand-navy;
    }
  }

  &__document-list {
    float: right;
    width: 208px;
    margin-top: 0;
    padding: 24px;
    overflow-y: auto;
    text-align: center;
    background: $color-grayscale-100;
    border-left: 2px solid $inactive-gray;

    &-popout {
      width: 216px;
    }

    .document-browser--responsive & {
      @include when-on-mobile {
        width: 220px;
      }
    }

    .document-thumbnail,
    .document-thumbnail__title,
    div.document-thumbnail,
    div.document-thumbnail__title {
      // override legacy styles from app/assets/stylesheets/modules/document-thumbnail.scss
      color: $brand-navy;
      background: transparent;
    }

    .document-thumbnail__title {
      padding-bottom: 8px;
      font-size: $font-size-sm-px;
      font-family: var(--font-weight-regular);
      line-height: 16px;
      text-align: left;

      .document-browser--responsive & {
        @include when-on-mobile {
          font-size: $font-size-lgg;
        }
      }
    }

    .scroll-anchor-container {
      position: relative;
    }

    .scroll-anchor {
      position: absolute;
      top: -115px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    height: 40px;
    color: $color-brand-0;
    background: #0072ce;

    a {
      color: $color-brand-0;
    }

    .document-browser--responsive & {
      @include when-on-mobile {
        height: 60px;
        font-size: $font-size-lgg;
        line-height: 60px;
      }
    }
  }

  &__header-current {
    flex: 1;
    padding-left: 25px;
    color: $color-brand-0;
    line-height: 40px;

    .document-browser--responsive & {
      @include when-on-mobile {
        line-height: 60px;
      }
    }
  }

  &__header-component-selection {
    & .component-selector {
      margin-left: 10px;

      & a {
        padding: 5px 10px;
        color: $inactive_gray;
        line-height: 40px;
        background: $near_white;
        border-right: solid;
        border-right-color: #9ea5a9;
        border-right-width: 1px;

        .document-browser--responsive & {
          @include when-on-mobile {
            font-size: $font-size-lgg;
          }
        }

        &.active {
          color: $blackish;
          background: $color-grayscale-0;
        }

        &.disabled {
          background: $color-grayscale-0;
          cursor: default;
        }

        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        &:last-child {
          border-right: none;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}

.shipment-selector {
  &__assign-documents-selector-item-label--disabled {
    color: $inactive-grey;
    text-decoration: line-through;
  }

  &__drops-info {
    margin: -0.5rem 0 0 3rem;
  }

  &__assign-documents-selector-item-label {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @at-root input#{&}__assign-documents-selector-checkbox {
    position: relative;
    top: 2px;
    margin: 0 0 0.5rem;
  }
}

.document-browser__type {
  display: block;
  padding: 10px 20px;
}

.document-browser__state {
  display: inline-block;
  color: $browser-state-color;
}

.document-browser__state.is-complete {
  color: $is-complete-color;
}

.document-browser__state.is-active {
  padding: 4px 10px;
  color: $is-active-color;
  background-color: $is-incomplete-color;
}

.document-browser__state.is-active.is-complete {
  color: $is-active-color;
  background-color: $is-complete-color;
}

.document-browser__type.selected {
  background: $light-highlight;
}

.document-browser__type.guessed {
  border: 3px solid $guessed-color;
}

.document-browser__thumbnail-container {
  width: 100%;
  height: $document-browser-thumbnail-height;
  margin-top: -$document-browser-thumbnail-height;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  list-style-type: none;
  background: $color-background-document-thumbnail;
}

.document-browser__thumbnail-container:hover {
  position: absolute;
  z-index: 20;
  height: auto;
  background: $color-background-document-thumbnail;
}

.document-browser__main-image {
  flex-grow: 1;
  flex-shrink: 0;
  max-width: 100%;
  border: 2px solid $inactive-gray;
}

.document-browser__zoomable-container {
  position: relative;
  overflow-x: auto;
  text-align: center;

  &--zoomed {
    cursor: move;
  }
}

.document-browser__zoomable-image-container {
  padding: 10px;
}

.document-browser__zoomable-image {
  border: 1px solid #c8d8eb;
  box-shadow: 0 4px 8px rgb(0 0 0 / 0.1);
}

.document-browser__main-image-container-no-images {
  padding: 10px;
}

.document-browser__react-component {
  display: flex;
  flex-direction: column;
}

.document-browser__container {
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 0; // CHROME BUG
  background: $near-white;
}

.document-browser__restricted-height {
  height: 650px;
}

.document-browser__full-height {
  height: 100%;
}

.document-browser__page-toolbar {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 0; // CHROME BUG
}

.document-browser__popout-page-toolbar {
  display: flex;
  flex-direction: column;
  min-height: 0; // CHROME BUG
}

.document-browser__main-image-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  background-color: $color-grayscale-200;
}

.document-browser__header-documents {
  width: 112px;
  padding-left: 25px;
  color: $color-brand-0;
  line-height: 40px;
}

.document-browser__header-close-link,
.document-browser__header-expand-link,
.document-browser__header-popout-link,
.document-browser__header-image-menu {
  width: 28px;
  margin: 0 4px;
}

.document-browser__header-close-link {
  font-size: $font-size-lggg;
  line-height: 44px;
}

.document-browser__header-expand-link {
  font-size: $font-size-lg;
  line-height: 2;
}

.document-browser__header-popout-link {
  font-size: $font-size-lg;
  line-height: 44px;
}

.document-browser__header-image-menu {
  position: relative;
  font-size: $font-size-lgggg;
  line-height: 1.4;
}

.document-browser__header-image-menu-content {
  position: absolute;
  right: 0;
  z-index: 9;
  display: flex;
  flex-direction: column;
  width: 200px;
  padding: 8px;
  background: $light-highlight;
  visibility: hidden;

  .document-browser__header-image-menu:focus-within > & {
    visibility: visible;
  }
}

.document-browser__header-image-menu-action {
  display: flex;
  flex-direction: row;
  align-items: end;
  padding: 8px;
  color: $blackish !important;

  & img {
    padding-right: 0.5em;
  }

  &:hover {
    text-decoration: underline;
  }
}

.document-browser__expanded-list-link {
  float: right;
  width: 18px;
  height: 18px;
  background-image: url("/runner/_work/wallet/wallet/client/elm-lib/audit/client/elm/shared-ui-v3/src/Ui/V3/../../../../../../app/assets/images/icons/group-collapse.png");
  background-size: cover;
}

.document-browser__collapsed-list-link {
  float: right;
  width: 18px;
  height: 18px;
  background-image: url("/runner/_work/wallet/wallet/client/elm-lib/audit/client/elm/shared-ui-v3/src/Ui/V3/../../../../../../app/assets/images/icons/group-expand.png");
  background-size: cover;
}

.document-browser__collapsed-list-load_ids-wrapper {
  text-align: center;
}

.document-browser__collapsed-list-load_ids-draft {
  color: $dark-gray;
}

.document-browser__collapsed-list-load_ids-exception {
  color: $caution;
}

.document-browser__collapsed-list-load_ids-processed {
  color: $highlight;
}

.document-browser__collapsed-list-load_ids-approved {
  color: $progress;
}

.document-browser__list-item {
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: left;
  border-top: 3px solid $dark-grey;

  &:first-child {
    padding-top: 0;
    border: none;
  }
}

.document-browser__list-item--filtered {
  padding-top: 0;
  padding-bottom: 0;
  border-top: none;
}

.document-browser__ignored {
  position: relative;

  .document-browser__collapsed-list-documents::after {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    color: $highlight;
    font-size: $font-size-lg-px;
    font-family: var(--font-weight-bold);
    background-color: $color-grayscale-0;
    border: 2px solid $highlight;
    content: "IGNORED";
  }
}

.document-browser__collapsed-list {
  display: flex;
  flex-direction: column;
  height: 150px;
  margin-bottom: 5px;
  padding: 5px;
  text-align: left;
  background-image: url("/runner/_work/wallet/wallet/client/elm-lib/audit/client/elm/shared-ui-v3/src/Ui/V3/../../../../../../app/assets/images/icons/document-group.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 50%;
  border: 2px solid $blackish;
  cursor: pointer;
  opacity: 0.4;

  h6 {
    flex: 1;
    font-size: $font-size-md-px;
  }
}

.document-browser__collapsed-list-documents {
  color: $inactive-grey;
  font-size: $font-size-sm-px;
  text-align: center;
}

.document-browser__pile-link {
  position: absolute;
  color: $color-brand-0;
  font-weight: var(--font-weight-bold);

  &:hover {
    color: $color-brand-0;
  }
}

.document-browser__next-in-pile-link {
  right: 5px;
  @extend .document-browser__pile-link;
}

.document-browser__previous-in-pile-link {
  left: 5px;
  @extend .document-browser__pile-link;
}

.document-browser__list-item--batched--primary,
.document-browser__list-item--batched {
  .document-browser__group-heading {
    margin-right: -25px;
    margin-left: -25px;
    padding: 5px;
    overflow-x: hidden;
    font-size: $font-size-md-px;
    white-space: nowrap;
    text-align: center;
    text-overflow: ellipsis;
    background: $neutral-gray;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.document-browser__main-image-wrapper {
  position: relative;
  border: solid rgb(0 0 0 / 0) 10px;
  border-bottom: none;

  &--scrollable {
    overflow: scroll;
  }
}

.document-browser__main-image-scroll-anchor {
  position: relative;
}

.document-browser__main-image-selected {
  width: 100%;
}

.document-browser__place--before-display {
  display: inline-block;
}

.document-browser__place--before::before,
.document-browser__place--inside .document-thumbnail-list::after {
  display: inline-block;
  width: 140px;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: $font-size-lggggg;
  text-align: center;
  content: "□";
}

.document-thumbnail--grouping {
  margin: 10px;
}

.document-thumbnail__title--grouping {
  position: relative;
  padding: 0;
}

.document-thumbnail__type--grouping {
  position: absolute;
  width: 100%;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.document-thumbnail__page {
  width: 160px;
  border: 2px solid #c8d8eb;
  border-radius: 4px;
}

.document-thumbnail__page--selected {
  border-color: $color-brand-600;
}
