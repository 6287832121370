.v3__chip {
  --chip-font-size: 12px;

  display: inline-flex;
  gap: var(--space-xxxs);
  align-items: center;
  padding: var(--space-xxxs) var(--space-xxs);
  font-weight: var(--font-weight-bold);
  border-radius: var(--corner-radius-l);

  & .v3__icon {
    width: 12px;
    height: 12px;
    margin: 2px;
  }
}

.v3__chip-text {
  display: inline-block;
  font-size: var(--chip-font-size);
  line-height: 1em;
}

.v3__chip__default {
  background-color: $color-grayscale-200;
  border: 1px solid $color-grayscale-400;

  & .v3__icon {
    color: $color-grayscale-900;
  }
}

.v3__chip__filled {
  color: $color-brand-0;
  background-color: $color-brand-600;
  border: 1px solid $color-brand-600;

  & .v3__icon {
    color: $color-brand-0;
  }
}

.v3__chip__disabled {
  color: $color-grayscale-300;
  border: 1px solid $color-grayscale-300;

  & .v3__icon {
    color: $color-grayscale-300;
  }
}

.v3__chip-exclude-icon {
  display: inline-flex;
  align-items: center;
}

.v3__chip-remove-button .v3__icon {
  width: 9px;
  height: 9px;
}

.v3__chip-remove-button,
button.v3__chip-remove-button /* Override for global styles outside of v3 CSS */ {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: $color-grayscale-400;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: none;
}
