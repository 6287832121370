.v3__table-wrapper {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: visible;
  background: $color-background-container;
  border: 1px solid var(--color-grayscale-300);
  border-radius: var(--corner-radius-m);
}

.v3__table-wrapper--no-overflow {
  overflow-x: visible;
}

.v3__table-inner-layout-container {
  display: flex;
  flex-basis: calc(100% - 264px);
  flex-grow: 1;
  overflow-y: visible;
}

.v3__table-inner-layout-container-loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(196 199 207 / 0.3);
}

.v3__table-inner-layout-container-loading-spinner {
  position: absolute;
}

.v3__table {
  flex-grow: 1;
  margin: 0;
  font-size: var(--font-size-h6);
  background: transparent;
  border: none;
  border-collapse: collapse;
}

.v3__table-cell {
  padding: 15px;
  font-size: var(--font-size-h6);
  line-height: 16px;
  text-align: left;
  border: none;
}

.v3__table-body .v3__table-body-row {
  background: $color-grayscale-0;
}

.v3__table-body-row--selected .v3__table-cell {
  background: $color-brand-100;
}

.v3__table .v3__table-cell--expandable {
  position: sticky;
  right: 0;
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  padding: 20px 10px;
  white-space: nowrap;
  border-left: none;
  box-shadow: 0 4px transparent, -4px 0 4px $color-grayscale-400;
}

.v3__table .v3__table-cell--expandable svg {
  color: $color-brand-500;
}

.v3__table .v3__table-body-row--selected .v3__table-cell--expandable {
  box-shadow: 0 4px 4px $color-brand-200, -4px 0 4px $color-brand-200;
}

.v3__table .v3__table-head .v3__table-cell--expandable svg {
  color: $color-grayscale-900;
}

.v3__table .v3__table-body-row--selected .v3__table-cell--expandable svg {
  color: $color-grayscale-900;
}

.v3__table .v3__table-body .v3__table-cell--expandable:hover svg {
  color: $color-grayscale-900;
}

.v3__table-head {
  position: sticky;
  top: 0;
  z-index: 1;
  color: $color-grayscale-900;
  background: $color-grayscale-0;
  border-top: none;
  border-radius: 0;
  box-shadow: 1px 1px var(--color-grayscale-400);
}

.v3__table-cell--head {
  font-weight: var(--font-weight-bold);
  background: $color-grayscale-0;
}

.v3__table-cell--head--text {
  display: inline-flex;
  flex-basis: 10px;
}

.v3__table-cell--body {
  font-weight: var(--font-weight-regular);
}

.v3__table-cell--expandable-wrapper {
  display: inline-block;
  padding: 0 20px;
  font-weight: var(--font-weight-bold);
}

.v3__table-body-row--expandable {
  display: none;
  width: 100%;
  margin-left: 10px;
  padding: 10px;
  background: $color-grayscale-0;
}

.v3__table-body-row--expandable--selected {
  display: block;
  width: auto;
  background: $color-grayscale-0;
  border: 1px solid $color-grayscale-400;
}

.v3__table-cell--head--right-aligned,
.v3__table-cell--right-aligned,
.v3__table-cell--expandable-header--right-aligned,
.v3__table-cell--expandable-data--right-aligned {
  text-align: right;
}

.v3__table .v3__table-body-row:nth-child(2n) {
  background: $color-grayscale-100;
}

.v3__table--expandable .v3__table-body-row:nth-child(4n + 3) {
  background: $color-grayscale-100;
}

.v3__table-body-row--selected
  .v3__table-cell:not(:last-child):not(:first-child) {
  box-shadow: 4px 4px 4px $color-brand-200;
}

.v3__table--expandable
  .v3__table-body-row--selected:nth-child(4n + 3)
  .v3__table-cell {
  background: $color-brand-100;
}

.v3__table-body-row--clickable,
.v3__table-cell--clickable {
  cursor: pointer;
}

.v3__table-body-row.v3__table-body-row--clickable {
  transition: background-color 100ms;

  &:hover {
    background-color: $color-grayscale-200;
  }
}

.v3__table-foot-row {
  background: $color-brand-100;
  border-top: 1px solid $color-grayscale-400;
}

.v3__table-cell--footer {
  font-weight: var(--font-weight-regular);
  border-top: solid $color-grayscale-400 1px;
}

.v3__table-toolbar {
  border-bottom: 1px solid $color-grayscale-300;
}

.v3__table-toolbar-title {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h4);
  line-height: 16px;
}

.v3__table-toolbar-elements-right {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xs);
  align-items: center;
}

.v3__table-toolbar-search {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  .v3__input-label {
    width: 100%;
  }

  .v3__table-search {
    height: 40px;
    border-radius: 50px;
  }

  .v3__input-icon--leading {
    color: $color-grayscale-600;
  }
}

.v3__table-search--medium {
  width: 240px;
}

.v3__table-toolbar-search--full {
  form,
  .v3__table-search--full {
    width: 100%;
  }
}

.v3__table-toolbar-filters {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xxxs);
  align-items: flex-end;
  margin: calc(-1 * var(--space-xxs)) 0;
}

.v3__table-toolbar-filters-trailing-button,
button.v3__table-toolbar-filters-trailing-button {
  margin: 0;
  padding: var(--space-xxxs);
  color: $color-grayscale-900;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-small-body);
  line-height: 1;
  white-space: nowrap;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: none;
}

.v3__table-toolbar-filters-trailing-button:hover {
  text-decoration: underline;
}

.v3__table--pagination {
  border-top: 1px solid $color-grayscale-300;
}

.v3__table-toolbar-options {
  display: flex;
  gap: var(--space-xxs);
}

@include v3-container-mobile {
  .v3__table-toolbar {
    flex-wrap: wrap;
  }

  .v3__table-toolbar-filters {
    flex-wrap: nowrap;
    order: 9;
    width: 100%;
    overflow-x: auto;
  }
}

.v3__table-layout-row-wrap {
  display: flex;
  flex-flow: row nowrap;
}

.v3__table-layout-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: auto;
}

.v3__table-layout-column--no-overflow {
  overflow-x: visible;
}

.v3__drawer-container {
  width: 30%;
  min-width: 200px;
  border-left: 1px solid $color-grayscale-300;
  border-radius: 0;
}
