.v3__flash {
  position: fixed;
  top: var(--space-s);
  right: var(--space-m);
  z-index: 98;
}

@media (width <= $max-mobile-width) {
  .v3__flash {
    right: var(--space-xxs);

    // Flash is positioned space-xxs from right side of mobile viewport,
    // so if it's too large to fit in the viewport, reduce its width by
    // 2 * space-xxs to have a comparable margin on the left.
    max-width: calc(100% - 2 * var(--space-xxs));
  }
}

.v3__flash-message {
  margin-bottom: 4px;
  transform: translateX(var(--space-xxxl));
  opacity: 0;
  transition: all 0.5s ease-in;
}

.v3__flash-message--active {
  transform: translateX(0);
  opacity: 1;
}
