// Designs: https://www.figma.com/file/p0qhjnEEtJdnVcox4zAPLN/3.0-Design-System?type=design&node-id=8294-113160&mode=dev

.v3__theme {
  --space-xxxs: 4px;
  --space-xxs: 8px;
  --space-xs: 16px;
  --space-s: 24px;
  --space-responsive-s: 24px;
  --space-m: 32px;
  --space-l: 40px;
  --space-xl: 48px;
  --space-xxl: 64px;
  --space-xxxl: 80px;
}

@media (width <= $max-mobile-width) {
  .v3__theme {
    --space-responsive-s: 16px;
  }
}

/*
 * Prefer using scss variables over css variables
 * wherever possible, because it gives compile-time
 * gurantees.
 */
// 4px
$space-xxxs: var(--space-xxxs);

// 8px
$space-xxs: var(--space-xxs);

// 16px
$space-xs: var(--space-xs);

// 16px mobile, 24px above
$space-responsive-s: var(--space-responsive-s);

// 24px
$space-s: var(--space-s);

// 32px
$space-m: var(--space-m);

// 40px
$space-l: var(--space-l);

// 48px
$space-xl: var(--space-xl);

// 64px
$space-xxl: var(--space-xxl);

// 80px
$space-xxxl: var(--space-xxxl);
