.v3__loadpay-tabs__tab,
button.v3__loadpay-tabs__tab /* Override for global styles outside of v3 CSS */ {
  margin: 0 var(--space-xxs) 1px var(--space-xxs);
  padding: var(--space-xxs) 0;
  color: var(--color-grayscale-900);
  border: 0;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h6);
  cursor: pointer;
  transition: none;
}

.v3__loadpay-tabs--presentation {
  display: flex;
  justify-content: left;
  border-bottom: 1px solid var(--color-grayscale-400);

  .v3__loadpay-tabs__tab {
    flex: 0;
    line-height: calc(1em + 2px);
    background-color: var(--color-grayscale-100);

    &:focus {
      z-index: 1; // this ensures the offset outline is not hidden by adjacent elements
      outline-color: var(--color-grayscale-900);
      outline-offset: 3px;
    }
  }

  .v3__loadpay-tabs__tab--selected {
    box-shadow: 0 2px var(--color-brand-600);
  }
}

.v3__loadpay-tabs__name-wrapper {
  gap: var(--space-xxxs);
  justify-content: center;
  white-space: nowrap;
}
