@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.v3__spin {
  animation: spin 2s infinite linear;
}
