$max-mobile-width: 480px;
$max-tablet-width: 768px;

// Desktop
@media (width > $max-tablet-width) {
  .mobile-exclusive {
    display: none;
  }
}

// Tablet/Mobile
@media (width <= $max-tablet-width) {
  // Tablet-specific
  @media ($max-mobile-width < width) {
    .mobile-exclusive {
      display: none;
    }
  }

  // Mobile-specific
  @media (width <= $max-mobile-width) {
    .mobile-hidden {
      display: none;
    }
  }
}
