.v3__layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100%;
}

// upper content (fixed)

.v3__layout__upper {
  position: fixed;
  top: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100dvh; /* stylelint-disable-line unit-no-unknown */
  pointer-events: none;
}

.v3__layout__upper__header,
.v3__layout__upper__sidebar {
  pointer-events: all;
}

.v3__layout__lower__header,
.v3__layout__lower__sidebar {
  visibility: hidden;
}

// lower content (scrollable)

.v3__layout__lower {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100dvh; /* stylelint-disable-line unit-no-unknown */
}

.v3__layout__sidebar_and_content {
  display: flex;
  flex-grow: 1;
  overflow: hidden;
}

.v3__layout__sidebar {
  height: 100%;
}

.v3__layout__content {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}
