$input-padding: 10px;
$icon-width: 16px;
$icon-to-text-spacing: 8px;
$border-radius: var(--corner-radius-sm);
$rounded-border-radius: var(--corner-radius-xl);
$border-width: 2px;
$icon-horz-offset: calc($border-width + $input-padding);
$with-icon-padding: calc($input-padding + $icon-width + $icon-to-text-spacing);
$gap: 6px;

.v3__input-label {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $gap;

  &.v3__input-label > .typeahead-menu {
    margin-top: var(--line-height-h6);
  }

  > #typeahead-input {
    height: var(--space-l);
    margin-top: var(--space-xxxs);
  }
}

.v3__input-wrapper {
  position: relative;
}

.v3__input-wrapper--has-leading-text-box::before,
.v3__input-wrapper--has-trailing-text-box::after {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--space-l);
  height: 100%;
  color: var(--color-grayscale-600);
  font-weight: var(--font-weight-semi-bold);
  font-family: $font-inter;
}

.v3__input-wrapper--has-leading-text-box::before {
  border-right: 1px solid var(--color-border-default);
  content: attr(data-leading-text);
}

.v3__input-wrapper--has-trailing-text-box::after {
  top: 0;
  right: 0;
  width: unset;
  padding: 0 10px;
  border-left: 1px solid var(--color-border-default);
  content: attr(data-trailing-text);
}

.v3__input-wrapper--has-leading-text-box--errored::before,
.v3__input-wrapper--has-trailing-text-box--errored::after {
  border-color: var(--color-danger-600);
}

.v3__input-wrapper--has-leading-text-box:has(.v3__input:focus)::before,
.v3__input-wrapper--has-trailing-text-box:has(.v3__input:focus)::after {
  border-color: $color-border-focus;
}

.v3__input-wrapper--has-leading-text-box--errored:has(.v3__input:focus)::before,
.v3__input-wrapper--has-trailing-text-box--errored:has(.v3__input:focus)::after {
  border-color: var(--color-danger-400);
}

.v3__input-wrapper--has-leading-text::before {
  position: absolute;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--space-m);
  height: 100%;
  color: var(--color-grayscale-600);
  content: attr(data-leading-text);
}

.v3__input {
  &#{&} {
    display: unset;
    box-sizing: border-box;
    width: 100%;
    min-width: 100px;
    height: 40px;
    margin: 0;
    padding: $input-padding;
    color: $color-grayscale-900;
    font-size: var(--font-size-body);
    line-height: 1.5em;
    background-color: $color-grayscale-0;
    border: solid $border-width $color-border-default;
    border-radius: var(--corner-radius-sm);
    outline: none;
    box-shadow: unset;
    transition: none;

    &:focus {
      background-color: $color-grayscale-0;
      border-color: $color-border-focus;
      box-shadow: unset;
    }

    &::placeholder {
      color: $color-grayscale-500;
    }

    &:disabled {
      color: $color-grayscale-500;
      background-color: $color-grayscale-200;
      border-color: $color-grayscale-300;
      opacity: 1;
    }

    &[type="date"].v3__input--empty:not(:focus) {
      color: $color-grayscale-500;
    }
  }

  .v3__input--rounded & {
    border-radius: $rounded-border-radius;
  }

  .v3__input--resize-vertical & {
    resize: vertical;
  }
}

.v3__input--right-align {
  text-align: right;

  &::placeholder {
    text-align: right;
  }
}

.v3__input--errored {
  &#{&} {
    border-color: $color-danger-600;

    &:focus {
      border-color: $color-danger-400;
    }
  }
}

.v3__input--has-leading-icon {
  &#{&} {
    padding-left: $with-icon-padding;
  }
}

.v3__input--has-trailing-icon {
  &#{&} {
    padding-right: $with-icon-padding;
  }
}

.v3__input--has-leading-text-box {
  &#{&} {
    padding-left: var(--space-xl);
  }
}

.v3__input--has-leading-text {
  &#{&} {
    padding-left: var(--space-s);
  }
}

.v3__input--has-trailing-text-box {
  &#{&} {
    padding-right: var(--space-xl);
  }
}

.v3__input-label-text {
  color: $color-grayscale-900;
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-h6);
  font-family: $font-inter;
  line-height: 1.5em;
}

.v3__input-validation {
  color: $color-danger-600;
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-h6);
  line-height: 1.5em;

  & > .v3__icon {
    margin-right: 8px;
    vertical-align: middle;
  }

  & > span {
    vertical-align: middle;
  }
}

.v3__input-icon {
  &#{&} {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
    color: $color-grayscale-900;

    // button-specific resets
    @at-root button#{&} {
      margin: unset;
      padding: 0;
      background-color: transparent;
    }
  }
}

.v3__input-icon--leading {
  &#{&} {
    left: $icon-horz-offset;
  }
}

.v3__input-icon--trailing {
  &#{&} {
    right: $icon-horz-offset;
  }
}
