.v3__navigation_top_bar__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 32px 10px 24px;
  background-color: $color-background-navigation;
  border-bottom: 2px solid $color-grayscale-300;
}

.v3__navigation_top_bar__logo {
  flex-grow: 1;
}

.v3__navigation_top_bar__utility_buttons {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
}

// Mobile/Tablet
@media (width <= $max-tablet-width) {
  .v3__navigation_top_bar__container {
    padding: 10px 16px;
  }

  .v3__navigation_top_bar__utility_buttons {
    & > button.v3__button--utility {
      color: $color-grayscale-900;
      background-color: $color-background-navigation;
      border: none;
    }
  }
}
