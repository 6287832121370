$button-font-family: inter;
$button-font-weight: 700;
$simple-select-font-size: 14px;
$custom-select-container-height: 40px;

@import "@okta/okta-signin-widget/css/okta-sign-in.min.css";
@import "../../elm-lib/audit/client/elm/shared-ui-v3/src/Ui/V3/styles-importer";
@import "colors.scss";
@import "images.scss";
@import "../app/src/Ui/UtilityButton.scss";

// override variables for LoadPay theme
$button-font-family: inter;
$button-font-weight: 700;
$simple-select-font-size: 14px;
$custom-select-container-height: 40px;

@import "../app/src/Ui/Text.scss";
@import "./okta-widget-overrides.scss";
@import "../app/src/Ui/Inline.scss";
@import "../app/src/Ui/Skeleton.scss";
@import "../app/src/Ui/V3LoadPay/Tabs.scss";

* {
  margin-bottom: 0;
  margin-top: 0;
}

a:active,
a:hover,
a:link,
a:visited {
  text-decoration: none;
}

input[type="radio"] {
  margin: 0;
}

.alert {
  color: red;
  font-weight: bold;
}

.sticky {
  background: #edeff2;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding-bottom: 24px;
}

.v3__table-head {
  z-index: 0;
}

.v3__table-inner-layout-container {
  display: flex;
}

body {
  margin: 0;
  padding: 0;

  background-color: $color-brown-100;
}

.v3__key-value--vertical {
  display: flex;
  flex-direction: column;
  gap: $space-xxxs;

  .v3__key-value-key {
    font-weight: $font-weight-body;
    line-height: $font-height-h5;
    font-size: $font-size-h5;
  }

  .v3__key-value-value {
    font-weight: $font-weight-body;
    line-height: $font-height-h5;
    font-size: $font-size-h5;
  }
}

.v3__key-value--bold-key {
  .v3__key-value-key {
    font-weight: $font-weight-semi-bold;
  }
}

.v3__key-value--bold-value {
  .v3__key-value-value {
    font-weight: $font-weight-bold;
  }
}

.v3__navigation_sidebar__menu_item--selected {
  background: $color-green-500;
  .v3__navigation_sidebar__menu_item_inner {
    border-bottom: 2px solid white;
  }

  .v3__navigation_sidebar__item_icon {
    color: white;
  }
  .v3__navigation_sidebar__item_label {
    color: white;
  }
}

.v3__navigation_sidebar__item_icon {
  color: white;
}

.v3__navigation_sidebar__item_label {
  color: white;
}

.v3__navigation_sidebar__container {
  background: $color-green-600;
}

.v3__info-button {
  color: $color-brown-500;
  &:hover {
    color: $color-brown-600;
  }
  &:active {
    color: $color-brown-700;
  }
}

.v3__modal {
  text-align: left;
  padding: $space-s;
  border-top-width: 0;
  border-radius: 0.625rem;
  background: $color-grayscale-0;
  box-shadow: 0px 0px 8px 0px rgba(0, 34, 56, 0.1);
  box-sizing: border-box;
  position: fixed;
  top: 50vh;
  left: 50vw;
  max-height: 90vh;
  transform: translate(-50%, -50%);
  display: flex;
  max-width: 92%;

  &__xsmall-container {
    width: 324px;
  }

  &__small-container {
    width: 404px;
  }

  &__medium-container {
    width: 488px;
  }

  &__large-container {
    width: 744px;
    height: 702px;
  }
}

.cg-busy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}

.cg-busy-backdrop {
  opacity: 0.7;
  background-color: gray;
}

hr {
  border-bottom: none;
  border-right: none;
  border-left: none;
  border-top: 1px solid $color-brown-300;
}

// must match the containerClass name in AddDebitCardModal.elm
.ingo-iframe-container iframe {
  border: none;

  // This is the general height of the contents on the add card screen.
  // Ingo tells us the height of the iframe + we resize, but that doesn't
  // get to us until after their page load.
  // Assuming a height during loading stops it from jumping between
  // heights while loading
  height: 750px;

  width: 100%;
}

// Sidebar

.v3__sidebar {
  width: 264px;
  height: 100%;
}

.v3__sidebar--menu-item:hover {
  color: $color-brand-150;
}

.v3__sidebar--menu-item:active {
  outline: 2px solid $color-grayscale-500;
  outline-offset: -2px;
}

// input

.date-picker {
  // fix safari defaults
  &#{&} {
    font-family: var(--font-family);
    min-width: 107px;
    display: revert;
  }
}

.v3__checkbox-label-text {
  font-size: $font-size-body-small;
}

input.v3__input.v3__input--has-leading-text-box {
  padding-left: var(--space-xl);
}

@media print {
  .hide-on-print {
    display: none !important;
  }

  /* the elm debugger doesn't have classes or ids - find it by its svg element */
  body > div:has(> svg) {
    visibility: hidden;
  }
}

.text-input--right-icon {
  position: absolute;
  top: 14px;
  right: 14px;
}

.obfuscated-field--is-obfuscated input {
  -webkit-text-security: disc;
}

/* toggleable eye for obfuscated form fields */
.obfuscated-field__toggle-obfuscation {
  top: 12px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
}

/* better tooltip positioning for a small info icon */
.v3__tooltip__info .v3__tooltip {
  right: -10px;
  transform: translate(100%, calc(-50% + 9px));
}

/* Overrides for native devices */

button {
  color: $color-brown-900;
}

.v3__action-card svg {
  color: $color-grayscale-550;
}

/*********************/
/* Responsive Design */
/*********************/

.v3__desktop {
  .v3__sidebar--collapsed {
    width: 48px;
  }
}

// Tablet/Mobile-Only
.v3__tablet-or-mobile {
  .v3__layout__sidebar {
    position: fixed;
    z-index: 1;
  }

  .v3__sidebar--collapsed {
    display: none;
  }

  .dashboard--tasks-and-fuel {
    flex-direction: column-reverse;
    align-items: stretch;
  }

  .company-layout-container--sidebar-open:after {
    background: $color-overlays-scrim;
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    overflow: hidden;
  }
}

.radio-card-input:focus-visible,
.radio-card-input:hover {
  border: 2px solid var(--color-grayscale-500);
  outline-color: var(--color-grayscale-500);
}

// Account Details Modal

@property --percentage {
  initial-value: 100%;
  inherits: false;
  syntax: "<percentage>";
}

.pie-timer {
  margin-right: 10px;
  margin-top: 5px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: $color-brand-600;
  mask: conic-gradient(#000, var(--percentage), transparent 0);
  // 1s buffer to prevent flicker
  animation: timer 91s infinite linear;
}

@keyframes timer {
  to {
    --percentage: 0%;
  }
}

.account-number-toggle {
  background-color: transparent;
  border: none;
  color: var(--color-grayscale-900);
}

.account-number-display {
  position: relative;
  left: -8px;
  top: -4px;
  padding-left: 10px;
}

.truncate-text-ellipsis {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
}
