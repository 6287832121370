.v3__checkbox {
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
  cursor: pointer;
}

.v3__checkbox--disabled {
  cursor: auto;
}

.v3__checkbox-icon {
  display: block;
  width: var(--space-xs);
  height: var(--space-xs);
  color: $color-button-primary-default;
  line-height: 0;
}

.v3__checkbox-icon--disabled {
  color: var(--color-navy-400);

  .v3__icon-checkbox-unchecked-fill {
    fill: var(--color-grayscale-300);
  }

  .v3__icon-checkbox-unchecked-border {
    stroke: var(--color-grayscale-400);
  }
}

.v3__checkbox-icon--error {
  color: var(--color-danger-600);

  .v3__icon-checkbox-unchecked-border {
    stroke: var(--color-danger-600);
  }
}

.v3__checkbox-input {
  position: absolute;
  left: -9999px;
}

.v3__checkbox-input:focus ~ .v3__checkbox-icon {
  border-radius: 1px;
  outline: 1px solid $color-button-primary-default;
}
