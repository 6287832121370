.v3__navigation_sidebar__container {
  display: flex;
  flex: none;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: content-box;
  width: 200px;
  height: calc(100vh - var(--topbar-height));
  padding-top: var(--space-xs);
  background-color: $color-background-navigation;
  border-right: 2px solid $color-grayscale-300;
}

.v3__navigation_sidebar__body {
  display: flex;
  flex-direction: column;
}

.v3__navigation_sidebar__menu_item,
.v3__navigation_sidebar__child_item {
  box-sizing: border-box;
  cursor: pointer;
}

a {
  &.v3__navigation_sidebar__menu_item,
  &.v3__navigation_sidebar__child_item {
    @include v3-a-reset;
  }
}

button {
  &.v3__navigation_sidebar__menu_item,
  &.v3__navigation_sidebar__child_item {
    @include v3-button-reset;
  }
}

a,
button {
  &.v3__navigation_sidebar__menu_item,
  &.v3__navigation_sidebar__child_item {
    &:hover {
      background-color: $color-grayscale-100;
    }

    &:active {
      background-color: $color-grayscale-300;
    }
  }

  &.v3__navigation_sidebar__child_item--selected {
    background-color: $color-grayscale-200;
  }
}

.v3__navigation_sidebar__menu_item_inner {
  display: flex;
  justify-content: left;
  column-gap: var(--space-xxs);

  .v3__navigation_sidebar__menu_item--selected & {
    padding-bottom: 4px;
    border-bottom: 2px solid $color-brand-600;
  }

  .v3__navigation_sidebar__container--collapsed & {
    padding-left: var(--space-xxs);
  }
}

.v3__navigation_sidebar__item_icon {
  display: flex;
  align-items: center;
  color: $color-grayscale-600;

  .v3__navigation_sidebar__menu_item--selected &,
  .v3__navigation_sidebar__menu_item:active & {
    color: $color-grayscale-900;
  }
}

.v3__navigation_sidebar__item_label {
  @extend .v3__type__new_h5;

  color: $color-grayscale-800;

  .v3__navigation_sidebar__container--collapsed & {
    display: none;
  }

  .v3__navigation_sidebar__menu_item--selected &,
  .v3__navigation_sidebar__menu_item:active & {
    color: $color-grayscale-900;
  }
}

.v3__navigation_sidebar__menu_item {
  height: 48px;
  padding: var(--space-xs) var(--space-s);

  &--selected {
    color: $color-grayscale-900;
  }

  .v3__navigation_sidebar__container--collapsed & {
    padding-right: var(--space-xxs);
    padding-left: var(--space-xxs);
  }
}

.v3__navigation_sidebar__child_item {
  height: 34px;
  padding: var(--space-xxs) var(--space-xxs) var(--space-xxs) var(--space-xl);
  color: $color-grayscale-900;

  .v3__navigation_sidebar__container--collapsed & {
    padding-left: var(--space-xxs);
  }
}

.v3__navigation_sidebar__child_item_label {
  @extend .v3__type__extra-small-body;

  color: $color-grayscale-800;

  .v3__navigation_sidebar__child_item--selected & {
    color: $color-brand-700;
    font-weight: var(--font-weight-bold);
  }
}

.v3__navigation_sidebar__footer {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: $color-background-navigation;
  border-top: 2px solid $color-grayscale-300;
}

.v3__navigation_sidebar__divider {
  @extend .v3__type__h6;

  padding: var(--space-s) var(--space-s) var(--space-xxs) var(--space-s);
  color: $color-grayscale-600;

  .v3__navigation_sidebar__container--collapsed & {
    visibility: hidden;
  }
}

.v3__navigation_sidebar__container--collapsed {
  width: 48px;
}

// Mobile/Tablet
@media (width <= $max-tablet-width) {
  .v3__navigation_sidebar__container {
    position: absolute;
    z-index: 20;
    width: 70%;
  }

  .v3__navigation_sidebar__container--collapsed {
    display: none;
  }
}
