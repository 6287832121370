@mixin tag-color($color) {
  color: var(--color-#{$color}-700);
  background-color: var(--color-#{$color}-100);
  border: solid 1.5px var(--color-#{$color}-400);
}

.v3__descriptor-tag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-h6);
  font-family: var(--font-family-bold);
  line-height: var(--line-height-h6);
  white-space: nowrap;
  vertical-align: text-bottom;
  border-radius: var(--corner-radius-sm);
}

.v3__descriptor-tag--color-success {
  @include tag-color("success");
}

.v3__descriptor-tag--color-info {
  @include tag-color("blue");
}

.v3__descriptor-tag--color-warning {
  @include tag-color("warning");
}

.v3__descriptor-tag--color-alert {
  @include tag-color("danger");
}

.v3__descriptor-tag--color-default {
  @include tag-color("navy");
}
