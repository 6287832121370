.v3__action-card {
  position: relative;
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--space-xs);
  background: white;
  border: 1px solid var(--color-grayscale-300);
  border-radius: var(--corner-radius-m);
  outline: 1px solid transparent;
  cursor: pointer;
}

.v3__action-card__content--label {
  white-space: nowrap;
}

.v3__action-card__content--description {
  color: var(--color-grayscale-600);
}

.v3__action-card__icon-and-content {
  display: flex;
  gap: var(--space-xxs);
  align-items: center;
}

.v3__action-card__content {
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-xxxs) var(--space-xxs);
  align-items: baseline;
  text-align: left;
}

.v3__action-card__content-column {
  flex-direction: column;
  gap: var(--space-xxxs);
}

.v3__action-card__icon {
  width: auto;
}

.v3__action-card-standalone {
  padding: 16px 24px;
}

.v3__action-card--vertical {
  display: block;
  flex: 1;
  justify-content: center;

  .v3__action-card__icon-and-content {
    flex-direction: column;
    height: 100%;
  }

  .v3__action-card__content {
    flex-direction: column;
  }

  .v3__action-card__content--label,
  .v3__action-card__content--description {
    margin: auto;
    white-space: normal;
    text-align: center;
  }
}

.v3__action-card--text-large {
  .v3__action-card__content--label {
    font-size: var(--font-size-h5);
  }

  .v3__action-card__content--description {
    font-size: var(--font-size-small-body);
  }
}

.v3__action-card--text-mixed {
  .v3__action-card__content--label {
    font-size: var(--font-size-h5);
  }

  .v3__action-card__content--description {
    font-size: var(--font-size-extra-small-body);
  }
}

.v3__action-card--text-small {
  .v3__action-card__content--label {
    font-size: var(--font-size-h6);
  }

  .v3__action-card__content--description {
    font-size: var(--font-size-extra-small-body);
  }
}

/* States for standalone action cards */

.v3__action-card--standalone {
  &.v3__action-card--default:focus-visible,
  &.v3__action-card--default:hover {
    border-color: var(--color-grayscale-500);
    outline-color: var(--color-grayscale-500);
  }

  &.v3__action-card--default:active {
    border-color: var(--color-blue-800);
    outline-color: var(--color-blue-800);
  }

  &.v3__action-card--selected {
    border-color: var(--color-border-selected);
    outline-color: var(--color-border-selected);
  }

  &.v3__action-card--disabled,
  &.v3__action-card--disabled .v3__action-card__content--label,
  &.v3__action-card--disabled .v3__action-card__content--description {
    color: var(--color-grayscale-500);
    background: var(--color-grayscale-200);
    cursor: default;
  }

  &.v3__action-card--error {
    border-color: var(--color-danger-600);
    outline-color: var(--color-danger-600);
  }
}

/* States for listed action cards */

.v3__action-card--in-list {
  margin: 0 var(--space-xs);
  padding: var(--space-xs) var(--space-xxxs);
  border: 0;
  border-radius: 0;

  &.v3__action-card--default:focus-visible,
  &.v3__action-card--default:hover {
    background: $color-grayscale-200;
  }

  &.v3__action-card--default:active {
    background: $color-grayscale-300;
  }

  &.v3__action-card--selected {
    z-index: 1;
    outline-color: var(--color-border-selected);
  }

  &.v3__action-card--disabled,
  &.v3__action-card--disabled .v3__action-card__content--label,
  &.v3__action-card--disabled .v3__action-card__content--description {
    color: $color-grayscale-500;
    background: $color-grayscale-200;
    cursor: default;
  }

  &.v3__action-card--error {
    background: $color-danger-100;
  }
}

.v3__action-card__list-divider {
  height: 1px;
  margin: 0 var(--space-xs);
  background: $color-grayscale-300;
  border: 0;
}
